/**
* CustomFileds fields
* 
*/
Formalis.InstantMessaging.CustomFields.prototype.Fields = function () {
    var that = {
        // Page to load for filling custom fields.
        CustomFieldsPageURL: '/Popup/CustomFields.aspx',
        CustomFieldsSubmitURL: '/Popup/CustomFieldList_Partial.aspx',

        // POST parameter names for _sessionCustomFieldPage
        CustomField_usage: 'usage',
        CustomField_filler: 'filler',
        CustomField_session: 'session',
        ev_show_post_session_modal: 'CustomFields_ShowPostSessionModal',
        div_pre_session_dialog: '#dialog-custom-fields',
        div_post_session_dialog: '#dialog-custom-fields',
        modal_session_custom_field: '#customFieldListModal'

}
    return that;
} ();

