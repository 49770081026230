/**
 * Event Handler for UpdateUserStatus Chrone Job Tick event.
 */
Formalis.InstantMessaging.OnlineUsers.prototype.UpdateUserStatus_TickHandler = function (eventArgs, scope) {
    scope.updateOnlineStatus(Formalis.InstantMessaging.UserID);
    scope.CheckAndHandleConnectionInstability();
};
/**
 * Binds event handlers for UpdateUserStatus events.
 */
Formalis.InstantMessaging.OnlineUsers.prototype.bindChroneJobEventHandlers_UpdateUserStatus = function () {
    this._updateUserStatus_ChroneJob.addTickHandler(this.UpdateUserStatus_TickHandler, this);
    this._updateUserStatus_ChroneJob.start();
};

/**
 * Event Handler for UpdateUserStatus Chrone Job Tick event.
 */
Formalis.InstantMessaging.OnlineUsers.prototype.getOnlineUsers_TickHandler = function (eventArgs, scope) {
    scope.getOnlineUsers();
    scope.CheckAndHandleConnectionInstability();
};
/**
 * Binds event handlers for UpdateUserStatus events.
 */
Formalis.InstantMessaging.OnlineUsers.prototype.bindChroneJobEventHandlers_GetOnlineUsers = function () {
    this.getOnlineUsers_TickHandler(null,this);
    this._getOnlineUsers_ChroneJob.addTickHandler(this.getOnlineUsers_TickHandler, this);
    this._getOnlineUsers_ChroneJob.start();
};