/**
 *  This method updates users online status
 * @param {inputType} input paramDescription
 * @returns {void} void
 */
Formalis.InstantMessaging.OnlineUsers.prototype.updateOnlineStatus = function(input){
    try{
        this.UpdateOnlineStatusDAO(input);
    }catch(err){
        this.HandleBusinessError(err);
    }
};

/**
 * Gets online users for the current business channel
 * @returns {void} void
 */
Formalis.InstantMessaging.OnlineUsers.prototype.getOnlineUsers = function(){
    try{
        this.GetOnlineUsersDAO();
    }catch(err){
        this.HandleBusinessError(err);
    }
};

/**
 * Stops all processes for Online Users class.
 * This includes all the chrone jobs, data connections to web services, internal evaluations.
 */
Formalis.InstantMessaging.OnlineUsers.prototype.stopAllProcesses = function(){
    this._getOnlineUsers_ChroneJob.stop();
    this.logError();
};

/**
 * Binds the main dom structure.
 */
Formalis.InstantMessaging.OnlineUsers.prototype.bindMainDOMStructure = function(){
    this.GenerateMainStructure();  
};
/**
 * Initializes chrone jobs and binds the main dom structure for online users in the page.
 */
Formalis.InstantMessaging.OnlineUsers.prototype.init = function(){
    this.checkAuthorizationDAO();    
};

/**
 * Initializes chrone jobs and binds the main dom structure for online users in the page.
 */
Formalis.InstantMessaging.OnlineUsers.prototype.startProcess = function () {
    this.bindMainDOMStructure();
    this.bindChroneJobEventHandlers_GetOnlineUsers();
    this.bindStartSessionClickEvent();
    //this.bindDraggableEvent();
    //this.bindChroneJobEventHandlers_UpdateUserStatus();
    this.getOnlineUsers();
};

/**
 * Initializes chrone jobs and binds the main dom structure for online users in the page.
 */
Formalis.InstantMessaging.OnlineUsers.prototype.hideOnlineUsersTable = function(){
    try{
        this.MainWrapper().hide();
        this.MainTableHolder().html('');
    }catch(err){
        this.HandleBusinessError(err);
    }
};


Formalis.InstantMessaging.OnlineUsers.prototype.getNewOnlineUsersForNotification = function(newOnlineList){
    var newUsers = new Array();
    var doesUserExists = false;
    for(var i in newOnlineList){
        doesUserExists = false;
        for(var j in this._onlineUsersList){
            if(this._onlineUsersList[j].Key == newOnlineList[i].Key){
                doesUserExists = true;
            }
        }
        if(!doesUserExists){
            newUsers.push(newOnlineList[i]);
        }
    }
    return newUsers;
};


Formalis.InstantMessaging.OnlineUsers.prototype.GetOnlineUsers_ActivityMonitor_StartHandler = function(event,scope){
    if(scope._authorizedToGetOnlineList 
        && scope._getOnlineUsers_ChroneJob == null){
        scope._getOnlineUsers_ChroneJob = new Formalis.ChroneJob("GetOnlineUsers", scope.GetOnlineUsersInterval);
        scope.bindChroneJobEventHandlers_GetOnlineUsers();
    }
};

Formalis.InstantMessaging.OnlineUsers.prototype.GetOnlineUsers_ActivityMonitor_StopHandler = function(event,scope){
    if(scope._authorizedToGetOnlineList 
        && scope._getOnlineUsers_ChroneJob != null){
        scope._getOnlineUsers_ChroneJob.stop();
        scope._getOnlineUsers_ChroneJob = null;
    }
};