/**
 * Adds Activity start Event Handler
 * @param {function} handler Event Handler
 * @param {object} scope Event Handler Scope
 */
Formalis.ActivityMonitor.prototype.AddActivityStartHandler = function (handler, scope) {
    this.Event.addListener(this.ActivityStart_EventName,handler,scope);
};

/**
 * Removes Activity start Handler
 * @param {function} Removes Activity Start Handler
 */
Formalis.ActivityMonitor.prototype.RemoveActivityStartHandler = function (handler) {
    this.Event.removeListener(this.ActivityStart_EventName, handler);
};

/**
 * Adds Activity stop Event Handler
 * @param {function} handler Event Handler
 * @param {object} scope Event Handler Scope
 * 
 */
Formalis.ActivityMonitor.prototype.AddActivityStopHandler = function (handler, scope) {
    this.Event.addListener(this.ActivityStop_EventName, handler, scope);
};

/**
 * emoves Activity stop Handler
 * @param {function} Removes Activity start Handler
 */
Formalis.ActivityMonitor.prototype.RemoveActivityStopHandler = function (handler) {
    this.Event.removeListener(this.ActivityStop_EventName, handler);
};