/**
 * Handles UpdateOnlineStatusDao events.
 * If number of caught errors during update online status exceeds threshold,
 * all the OnlineUser class events are stopped resulting in an end in page activity for the class.
 * @param {object} errorObject Error Object
 * @returns {void} void
 */
Formalis.ServiceLog.prototype.Handle_SaveLog_Error = function(errorObject){
    this._error_SaveLog.push(errorObject);
    this.CheckAndHandleConnectionInstability();
    
};
Formalis.ServiceLog.prototype.CheckAndHandleConnectionInstability = function(){
    
    if(this._error_SaveLog.length>this.threshold_error_SaveLog){
        //end page activity.
        this.stopAllProcesses();
        //Display error text
    }
   
};