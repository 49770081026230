/**
 * @description 
 * @param {object} data inputDescription 
 * @requires jtemplates jquery plugin
 */
Formalis.InstantMessaging.OnlineUsers.prototype.GenerateOnlineListTable = function(data){
    var obj = this;
    try{
        /** @property {object} $ Javascript Framework (default JS Framework is JQuery */
        var mainHolder = this.Controls.MainTableHolder();
        mainHolder.setTemplate(this.HTMLTemplates.CreateOnlineUsersTable);
        mainHolder.setParam('userMessageRequestClass',this.Fields.userMessageRequestClass);
        mainHolder.processTemplate(data);
        
        //this.blinkInvitationTableHeader();
    }catch(err){
        obj.HandleBusinessError(err);
    }
};

Formalis.InstantMessaging.OnlineUsers.prototype.ShowNoUsersOnlineWindow= function(){
    /** @property {object} $ Javascript Framework (default JS Framework is JQuery */
    ;
    var mainHolder = this.Controls.MainTableHolder();
    mainHolder.setTemplate(this.HTMLTemplates.OnlineUsers_NoUsersOnline);
    mainHolder.setParam('Prefix', this.Fields.DOMElement_prefix);
    mainHolder.setParam('NoUsersOnlineText', this.Resource.Lang.noUsersOnline);
    mainHolder.processTemplate();
    
    //this.unBlinkInvitationTableHeader();
    
};

Formalis.InstantMessaging.OnlineUsers.prototype.GenerateMainStructure= function(){
    /** @property {object} $ Javascript Framework (default JS Framework is JQuery */
    var $ = Formalis.ClassBase.prototype.$;
    var mainHolder = $("#" + Formalis.InstantMessaging.InstantMessagingMainHolderOnlineUsers);
    mainHolder.setTemplate(this.HTMLTemplates.OnlineUsers_MainStructure);
    mainHolder.setParam('Prefix', this.Fields.DOMElement_prefix);
    mainHolder.setParam('OnlineUsers', this.Resource.Lang.onlineUsers);
    mainHolder.processTemplate();
};




Formalis.InstantMessaging.OnlineUsers.prototype.blinkInvitationTableHeader = function () {
    /** @property {object} $ Javascript Framework (default JS Framework is JQuery */
    var mainTableHolderDisplayValue = this.Controls.MainTableHolder().css("display");
    if(mainTableHolderDisplayValue == "none"){
        this.Controls.MainHandle().blink();
    }
};

Formalis.InstantMessaging.OnlineUsers.prototype.unBlinkInvitationTableHeader = function () {
    /** @property {object} $ Javascript Framework (default JS Framework is JQuery */
    var mainTableHolderDisplayValue = this.Controls.MainTableHolder().css("display");
    if(mainTableHolderDisplayValue == "none"){
        this.Controls.MainHandle().unblink();
    }
};