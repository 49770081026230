/**
 * Updates users online status
 * @param {integer} UserID User's ID
 * @returns {void} void
 */
Formalis.InstantMessaging.Invitation.prototype.getNewInvitationsDAO = function(){
    var obj = this;
    
    this.proxy.InvokeJSONP("invitation","GET",null,new Array("new"),function(data){
        if(data != null && data.Success===true){
            Formalis.InstantMessaging.HandleDAOResponse(data);
            obj.Handler_GetNewInvitations(data);
        }else{
            obj.Handle_GetNewInvitations_Error(data);
        }
    },function(err){
        obj.Handle_GetNewInvitations_Error(err);
    },true,Formalis.InstantMessaging.SetAuthUrl);
};

/**
 * Updates users online status
 * @param {integer} UserID User's ID
 * @returns {void} void
 */
Formalis.InstantMessaging.Invitation.prototype.checkInvitationStatusDAO = function(){
    var obj = this;
    
    this.proxy.InvokeJson("invitation","GET",null,new Array(""+this._invitationID,"check"),function(data){
        if(data != null && data.Success===true){
            Formalis.InstantMessaging.HandleDAOResponse(data);
            obj.Handler_CheckInvitationStatus(data);
        }else{
            obj.Handle_CheckInvitationStatus_Error(data);
        }
    },function(err){
        obj.Handle_GetNewInvitations_Error(err);
    },true,Formalis.InstantMessaging.SetAuthHeader);
};

/**
 * Updates users online status
 * @param {integer} UserID User's ID
 * @returns {void} void
 */
Formalis.InstantMessaging.Invitation.prototype.checkAuthorizationDAO = function(){
    var obj = this;
    
    this.proxy.InvokeJSONP("invitation","GET",null,new Array("authorization"),function(data){
        if(data != null && data.Success===true){
            Formalis.InstantMessaging.HandleDAOResponse(data);
            obj.Handler_CheckAuthorization(data);
        }else{
            obj.Handle_CheckAuthorization_Error(data);
        }
    },function(err){
        obj.Handle_GetNewInvitations_Error(err);
    },true,Formalis.InstantMessaging.SetAuthUrl);
};

/**
* Updates users online status
* @param {integer} UserID User's ID
* @returns {void} void
*/
Formalis.InstantMessaging.Invitation.prototype.checkSendInvitationAuthorizationDAO = function () {
    var obj = this;

    this.proxy.InvokeJSONP("invitation", "GET", null, new Array("send","authorization"), function (data) {
        if (data != null && data.Success === true) {
            Formalis.InstantMessaging.HandleDAOResponse(data);
            obj.Handler_CheckSendInvitationAuthorization(data);
        } else {
            obj.Handle_CheckSendInvitationAuthorization_Error(data);
        }
    }, function (err) {
        obj.Handle_GetNewInvitations_Error(err);
    }, true, Formalis.InstantMessaging.SetAuthUrl);
};

Formalis.InstantMessaging.Invitation.prototype.cancelPendingInvitationDAO = function(){
    var obj = this;
    
    this.proxy.InvokeJson("invitation","DELETE",null,new Array(""+this._invitationID),function(data){
        if(data != null && data.Success===true){
            /* Return value from cancelling an invitation is not used besides Success checking. */
            Formalis.InstantMessaging.HandleDAOResponse(data);

            var serviceLogObj = new Formalis.ServiceLog(Formalis.InstantMessaging.ServiceLogWebServiceBaseURL);
            serviceLogObj.saveLog("cancelPendingInvitationDAO : " + data);

        }else{
            obj.Handle_cancelPendingInvitation_Error(data);
        }
    },function(err){
        obj.Handle_cancelPendingInvitation_Error(err);
    },true,Formalis.InstantMessaging.SetAuthHeader);
};