/**
* @fileOverview  Instant Messaging Base Function 
* @name Javascript Base File - Namespace declaration
* @author Formalis\Mehmet Ali Koeken    
* @version 1.0
*/

/**
* @namespace Formalis.InstantMessaging namespace declaration
*/
Formalis.InstantMessaging = function () {

    var that = {
        EncryptedUsername: '',
        //        ServiceLogWebServiceBaseURL: 'http://demo.helpalive.net/',
        //        InternalWebServiceServerURL: 'http://demo.helpalive.net/',
        //        PublicWebServiceServerURL: 'http://www.helpalivewebservice.com/',
        //        WebSiteURL: 'http://test.helpalive.com',
        ServiceLogWebServiceBaseURL: 'http://formalisdemo.helpalive.f2/',
        InternalWebServiceServerURL: 'http://formalisdemo.helpalive.f2/',
        PublicWebServiceServerURL: 'http://formalisdemo.helpalive.f2/',
        WebSiteURL: 'http://formalisdemo.helpalive.f2/',
        TimeCalibrationValues: {},
        Debug: false,
        BusinessChannelID: 1,

        ErrorWindowHolder_DOMElement: '',
        CurrentUser: null,
        _akey: '',
        _avalue: '',
        InstantMessagingMainHolderInvitations: 'InstantMessagingMainHolderInvitations',
        InstantMessagingMainHolderOnlineUsers: 'InstantMessagingMainHolderOnlineUsers',
        TimeCalibration: function (ServerTime) {
            var clientTime = new Date();
            var aServerTime = ServerTime.split(':');
            var hour = parseInt(aServerTime[0]) - clientTime.getHours();
            var minute = parseInt(aServerTime[1]) - clientTime.getMinutes();
            var seconds = parseInt(aServerTime[2]) - clientTime.getSeconds();
            Formalis.InstantMessaging.TimeCalibrationValues = {
                Hour: hour,
                Minute: minute,
                Second: seconds
            };
        },
        PopupWindowSize: {
                width:500,
                height:513
            }
    }
    return that;
} ();

