Formalis.InstantMessaging.Invitation.prototype.bindStartSessionClickEvent = function() {
    /** @property {object} $ Javascript Framework (default JS Framework is JQuery */
    var $ = Formalis.ClassBase.prototype.$;
    var obj = this;
    $("." + this.Fields.Invitation_StartSession_LinkClass).off('click.StartSession_LinkClass');
    $("." + this.Fields.Invitation_StartSession_LinkClass).on('click.StartSession_LinkClass', function(e) {
        e.preventDefault();
        obj.handleStartSessionClickEvent($(this));
    });
    if(this.Controls.MainWrapper().length <= 0) {
        this.GenerateMainStructure();
    }
};
Formalis.InstantMessaging.Invitation.prototype.bindStartSessionEvent = function (data) {
    var windowKey = "";
    var invitationid = "";

    data.forEach(function (invitation) {
        window.Store.acceptedInvitationIds.push(invitation.UniqueId);
        windowKey = invitation.UniqueId;
        invitationid = invitation.InvitationID;
    });

    var encrytedUsernameText = "&eusername=" + Formalis.InstantMessaging.EncryptedUsername;
    Formalis.PopupWindow.open(Formalis.InstantMessaging.WebSiteURL + "/Popup/MessagingWindow.aspx?IID=" + invitationid + encrytedUsernameText, windowKey, 'width=' + Formalis.InstantMessaging.PopupWindowSize.width + ',height=' + Formalis.InstantMessaging.PopupWindowSize.height + ',toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,copyhistory=yes,resizable=no', false)
};
Formalis.InstantMessaging.Invitation.prototype.bindDraggableEvent = function() {
    /** @property {object} $ Javascript Framework (default JS Framework is JQuery */
    var $ = Formalis.ClassBase.prototype.$;
    var obj = this;
    //TODO - this part must be optimized.
    if(this.inTableHeight == 0) {
        this.inTableHeight = obj.Controls.MainBody().height();
    }
    this.Controls.MainWrapper().draggable({
        handle : "#" + obj.Fields.DraggableHandle,
        stop : function(event, ui) {
            Formalis.Utils.RecordElementPositionToCookie(obj.Controls.MainWrapper().attr("id"));
        }
    //, containment: "body"
    });

    var objectState = Formalis.Utils.ReadElementPositionToCookie(this.Controls.MainWrapper().attr("id"));
    if(objectState != null) {
        this.Controls.MainWrapper().css("top", objectState.top);
        this.Controls.MainWrapper().css("left", objectState.left);
    }

    var objectStateDisplay = Formalis.Utils.ReadElementDisplayValueToCookie(this.Controls.MainTableHolder().attr("id"));
    if(objectStateDisplay != null && objectStateDisplay.display != null && objectStateDisplay.display != undefined) {
        obj.Controls.MainTableHolder().css("display", objectStateDisplay.display);
        //AdjustMainWrapperHeight();
        if(obj.Controls.MainTableHolder().css("display") == "none") {
            var height = obj.Controls.MainWrapper().height();
            obj.Controls.MainWrapper().height(height - obj.inTableHeight);
        }
    }

    this.Controls.MainWrapper().corner();
    //this.Controls.MainTableHeaderImage().css('cursor', 'pointer');
    this.Controls.MainWrapper().css('z-index', '34598');
    this.Controls.MainTableHeaderImage().off("click.MainTableHolder");
    this.Controls.MainTableHeaderImage().on("click.MainTableHolder", function(e) {
        obj.Controls.MainTableHolder().toggle();
        Formalis.Utils.RecordElementDisplayValueToCookie(obj.Controls.MainTableHolder().attr("id"));
        AdjustMainWrapperHeight();
    });
    function AdjustMainWrapperHeight() {
        if(obj.Controls.MainTableHolder().css("display") == "none") {
            var height = obj.Controls.MainWrapper().height();
            obj.Controls.MainWrapper().height(height - obj.inTableHeight);
        } else {
            var height = obj.Controls.MainWrapper().height();
            obj.Controls.MainWrapper().height(height + obj.inTableHeight);
        }
    }

};
/**
 * Binds live support click event handler.
 *
 */
Formalis.InstantMessaging.Invitation.prototype.bindLiveSupportButton = function(bindLiveSupportButton) {
    /** @property {object} $ Javascript Framework (default JS Framework is JQuery */
    var $ = Formalis.ClassBase.prototype.$;
    var obj = this;
    bindLiveSupportButton.live('click', function(e) {
        e.preventDefault();
        var windowkey = "windowKey";
        var encrytedUsernameText = obj.EncryptedUserName;
        Formalis.PopupWindow.open(Formalis.InstantMessaging.WebSiteURL + "/Popup/Invitation.aspx" + encrytedUsernameText, 'MessageWindow_' + windowkey, 'width=' + Formalis.InstantMessaging.PopupWindowSize.width + ',height=' + Formalis.InstantMessaging.PopupWindowSize.height + ',toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=no,copyhistory=yes,resizable=no', false)
    });
};

Formalis.InstantMessaging.Invitation.prototype.bindOnunloadEvent = function () {
    var obj = this;
    var $ = Formalis.ClassBase.prototype.$;

    $(window).on('beforeunload', function () {
        if (obj.isInvitationPending) {
            return obj.Resource.Lang.AreYouSureToCancelInvitation;
        }
    });

    $(window).unload(function () {
        if (obj.isInvitationPending) {
            // oturum devam etmiyorsa sonland�r�lacak!
            obj.cancelPendingInvitation(true);
        }
    });

};