/**
* Online Clients fields
*
*/
Formalis.InstantMessaging.MessageWindow.Fields = function () {
    var that = {
        AuditorMessageClass: 'MessageWindow_AuditorMessageClass',
        UserMessageClass: 'MessageWindow_UserMessage',
        InformationMessageClass: 'MessageWindow_InformationMessage',
        MessageTime_operator: 'chat_time_stamp',
        MessageSender_operator: 'chat_operator',
        MessageText_operator: 'chat_operator',
        MessageTime_user: 'chat_time_stamp',
        MessageSender_user: 'chat_user',
        MessageText_user: 'chat_user',
        OperatorWaitingClass: 'MessageWindow_OperatorWaitingClass',
        AjaxLoaderImageClass: 'MessageWindow_AjaxLoaderImageClass',
        OperatorWaitingClassHolder: 'OperatorWaitingClassHolder',
        TargetApplicationConvertToIssueURL: '',
        ev_session_end: 'MessageWindow_SessionEnd',
        ev_on_init: 'MessageWindow_OnInit',
        SessionCustomFieldsUrl: '/Popup/SessionCustomFields_Partial.aspx',
        CloseWindowAfterEndSession: '@=CloseWindowAfterEndSession',
        CloseWindowAfterEndSessionTimeout: 3000,

        ImgRemoteCliUnackSrc: 'ImgRemoteCliUnackSrc',
        ImgRemoteCliAckSrc: 'ImgRemoteCliAckSrc',
        ImgServerUnackSrc: 'fa fa-arrow-up ImgServerUnackSrc',
        ImgServerAckSrc: 'fa fa-check-circle-o ImgServerAckSrc'
    }
    return that;
}();

Formalis.InstantMessaging.MessageWindow.prototype.Fields = Formalis.InstantMessaging.MessageWindow.Fields;