/**
 * @fileOverview Service Proxy
 * @name Class Base Class Definition
 * @author Formalis\Mehmet Ali Köken
 * @version 1.0
 */

/**
 * Method main structure: jQuery AJAX calls to a WCF REST Service - Rick Strahl's Web Log
 * http://www.west-wind.com/weblog/posts/2008/Apr/21/jQuery-AJAX-calls-to-a-WCF-REST-Service
 * @param {string} serviceUrl service URL (e.g. IM.svc)
 * @param {string} server IF another domain is going to be used, domain name comes here. (e.g. http://www.example.com)
 */
Formalis.ServiceProxy = function (serviceUrl, server) {
    this.serviceUrl = serviceUrl;
    this.server = server;
};

/**
 * Invokes Jquery ajax method. Response result is feeded to callback method for it to process it.
 * @param {string} method WCF Service Method name
 * @param {string} type HTTP Method (GET,POST,PUT,DELETE)
 * @param {object} data  data sent to the method (cases for post and put) (must be in json format)
 * @param {function} callback Request Success callback method
 * @param {function} error Request Fail callback method
 * @param {boolean} bare See Bare Messages title in the Article (jQuery AJAX calls to a WCF REST Service)
 */
Formalis.ServiceProxy.prototype.InvokeJSONP = function (method, type, data, queryParamList, callback, error, bare, beforeSendFunc,dataType) {
    var $ = Formalis.ClassBase.prototype.$;
    try{
        if(dataType == null || dataType == undefined){
            dataType = "jsonp";
        }
        
        
        var _I = this;
        // *** Convert input data into JSON - REQUIRES Json2.js
        var json = null;
        if (data != null)
            json = JSON.stringify(data);

        // *** The service endpoint URL        
        var url = "";
        if (this.server != null) url = this.server
        url += _I.serviceUrl + method;
 
        if (queryParamList != null && queryParamList.length > 0) {
            for (var i = 0; i < queryParamList.length; i++) {
                url += '/' + queryParamList[i];
                //this part is needed in order to prevent 307 temp redirect from WCF WEb Service. All the uris must finish with forward slash
                if(i==(queryParamList.length-1)){
                    url+= "/";
                }
            }
        }
        if(beforeSendFunc != null || beforeSendFunc != undefined){
            url+=beforeSendFunc();
        }
        //added for jsonp support. Must be removed for normal json requests.
        var encoded = "";//Base64.encode(username + ":" + password);
        /* Ajax request is created here.
    * Notice that we always use json as content type.
    * Recieved data is taken as text because incoming json will be parsed by using JSON2.js methods.
    */
        $.ajax({
            url: url,
            data: json,
            type: type,
            processData: false,
            contentType: "application/json",
            timeout: 10000,
            dataType: dataType,  // not "json" we'll parse,
            success:
            function (res) {
                //try{
                    if (!callback) return;

                    // *** Use json library so we can fix up MS AJAX dates
                    //var result = JSON.parse(res);
                    // *** Bare message IS result
                    var result = "";
                    if(dataType == "jsonp"){
                        result = res;
                    }else if(dataType == "text"){
                        result = JSON.parse(res);
                    }
                    if (bare)
                    {
                        callback(result);
                        return;
                    }
                    // *** Wrapped message contains top level object node
                    // *** strip it off
                    for (var property in result) {
                        callback(result[property]);
                        break;
                    }
                //}catch(err){
                //    throw err;
                //}
            },
            error: function (xhr, textStatus, errorThrown) {
                if (!error) return;
                //if (xhr.responseText) {
                //var err = JSON.parse(xhr.responseText);
                var err = xhr.responseText;
                if (err)
                    error(err);
                else
                    error({
                        Message: "Unknown server error."
                    })
                //}
                return;
            }
        });
    }catch(err){
        throw err;
    }
};


/**
 * Invokes Jquery ajax method. Response result is feeded to callback method for it to process it.
 * @param {string} method WCF Service Method name
 * @param {string} type HTTP Method (GET,POST,PUT,DELETE)
 * @param {object} data  data sent to the method (cases for post and put) (must be in json format)
 * @param {function} callback Request Success callback method
 * @param {function} error Request Fail callback method
 * @param {boolean} bare See Bare Messages title in the Article (jQuery AJAX calls to a WCF REST Service)
 */
Formalis.ServiceProxy.prototype.InvokeJson = function (method, type, data, queryParamList, callback, error, bare, beforeSendFunc) {
    var $ = Formalis.ClassBase.prototype.$;
    //try{
        var _I = this;
        // *** Convert input data into JSON - REQUIRES Json2.js
        var json = null;
        if (data != null)
            json = JSON.stringify(data);

        // *** The service endpoint URL        
        var url = "";
        if (this.server != null) url = this.server
        url += _I.serviceUrl + method;
 
        if (queryParamList != null && queryParamList.length > 0) {
            for (var i = 0; i < queryParamList.length; i++) {
                url += '/' + queryParamList[i];
                //this part is needed in order to prevent 307 temp redirect from WCF WEb Service. All the uris must finish with forward slash
                if(i==(queryParamList.length-1)){
                    url+= "/";
                }
            }
        }
        
        //added for jsonp support. Must be removed for normal json requests.
        var encoded = "";//Base64.encode(username + ":" + password);
        /* Ajax request is created here.
    * Notice that we always use json as content type.
    * Recieved data is taken as text because incoming json will be parsed by using JSON2.js methods.
    */
        $.ajax({
            url: url,
            data: json,
            type: type,
            processData: false,
            contentType: "application/json",
            timeout: 10000,
            beforeSend:beforeSendFunc,
            //        'beforeSend': function (xhr) {
            //            xhr.setRequestHeader("Authorization", "Basic  " + encoded )
            //        },
            //dataType: "text",  // not "json" we'll parse
            dataType: 'text',  // not "json" we'll parse,
            //jsonpCallback: 'method',
            success:
            function (res) {
                //try{
                    //alert(res);
                    if (!callback) return;

                    // *** Use json library so we can fix up MS AJAX dates
                    //var result = JSON.parse(res);
                    // *** Bare message IS result
                    if(res == null || res==undefined || res.length ==0){
                        error();
                        return;
                    }
                    var result = JSON.parse(res);
                    if (bare)
                    {
                        callback(result);
                        return;
                    }
                    // *** Wrapped message contains top level object node
                    // *** strip it off
                    for (var property in result) {
                        callback(result[property]);
                        break;
                    }
                //}catch(err){
                //    throw err;
                //}
            },
            error: function (xhr, textStatus, errorThrown) {
                if (!error) return;
                if (xhr.responseText) {
                    //var err = JSON.parse(xhr.responseText);
                    var err = xhr.responseText;
                    if (err)
                        error(err);
                    else
                        error({
                            Message: "Unknown server error."
                        })
                }
                return;
            }
        });
    //}catch(err){
    //    throw err;
    //}
};


/**
 * @description Default Error Handler function
 */
Formalis.ServiceProxy.prototype.ErrorHandler = function (ex) {
    throw ex;
};