/**
 * @fileOverview Class Base Class Definition
 * @name Class Base Class Definition
 * @author Formalis\Mehmet Ali Köken
 * @version 1.0
 */

/**
 * @constructor
 */
Formalis.ClassBase = function () {
   
};

//Assign default classbase 
//Define javascript framework
Formalis.ClassBase.prototype.$ = jQuery;

//Define javascript framework
Formalis.ClassBase.prototype.CurrentLanguage = "TR";
