/**
 * @description Class Controls. Uses JS Framework (default JQuery) to get Page Control
 * 
 */
Formalis.InstantMessaging.OnlineUsers.prototype.Controls = function () {
    /** @property {object} $ Javascript Framework (default JS Framework is JQuery */
    var $ = Formalis.ClassBase.prototype.$;
    var prefix = Formalis.InstantMessaging.OnlineUsers.prototype.Fields.DOMElement_prefix;
    var that = {
        MainTableHolder: function () {
            return $('#InstantMessagingMainHolderOnlineUsers');
        },
        MainWrapper: function () {
            return $('#HA_Users_Popup_Window_Wrapper')
        },
        MainTableText: function () {
            return $('#HA_Users_Popup_Window_Header_Text');
        },
        MainTableHeaderImage: function () {
            return $('#HA_Users_Popup_Window_Header_Image');
        },
        MainHandle: function () {
            return $('#HA_Users_Popup_Window_Header');
        }
    }
    return that;
} ();

