
/**
*
* @param {object} data inputDescription
* @requires jtemplates jquery plugin
*/
Formalis.InstantMessaging.Invitation.prototype.GenerateNewInvitationsTable = function (data) {
    /** @property {object} $ Javascript Framework (default JS Framework is JQuery */
    var obj = this;
    try {
        console.log(data);
        var txt = document.createElement("textarea");
        for (var i in data.Invitations) {
            txt.innerHTML = data.Invitations[i].InvitationTopic;
            data.Invitations[i].InvitationTopic = txt.value;
        }

        console.log(data);
        var mainHolder = obj.Controls.MainTableHolder();
        mainHolder.setTemplate(obj.HTMLTemplates.CreateNewInvitationsTable);
        mainHolder.setParam('Prefix', obj.Fields.DOMElement_prefix);
        mainHolder.setParam('StartSessionLinkClass', obj.Fields.Invitation_StartSession_LinkClass);
        mainHolder.processTemplate(data);
    } catch (err) {
        obj.HandleBusinessError(err);
    }
    //this.blinkInvitationTableHeader();
};

/**
* @description
* @param {object} data inputDescription
* @requires jtemplates jquery plugin
*/
Formalis.InstantMessaging.Invitation.prototype.ShowNoNewInvitationsWindow = function (data) {
    /** @property {object} $ Javascript Framework (default JS Framework is JQuery */
    var mainHolder = this.Controls.MainTableHolder();
    mainHolder.setTemplate(this.HTMLTemplates.Invitations_NoNewInvitations);
    mainHolder.setParam('Prefix', this.Fields.DOMElement_prefix);
    mainHolder.setParam('Invitations', this.Resource.Lang.NoNewInvitations);
    mainHolder.processTemplate();

    //this.unBlinkInvitationTableHeader();
};

Formalis.InstantMessaging.Invitation.prototype.GenerateMainStructure = function () {
    /** @property {object} $ Javascript Framework (default JS Framework is JQuery **/
    var $ = Formalis.ClassBase.prototype.$;
    var mainHolder = $("#" + Formalis.InstantMessaging.InstantMessagingMainHolderInvitations);
    mainHolder.setTemplate(this.HTMLTemplates.Invitations_MainStructure);
    mainHolder.setParam('Prefix', this.Fields.DOMElement_prefix);
    mainHolder.setParam('Invitations', this.Resource.Lang.Invitations);
    mainHolder.processTemplate();
};


Formalis.InstantMessaging.Invitation.prototype.blinkInvitationTableHeader = function () {
    /** @property {object} $ Javascript Framework (default JS Framework is JQuery */
    var mainTableHolderDisplayValue = this.Controls.MainTableHolder().css("display");
    if (mainTableHolderDisplayValue == "none") {
        this.Controls.MainHandle().blink();
    }
};

Formalis.InstantMessaging.Invitation.prototype.unBlinkInvitationTableHeader = function () {
    /** @property {object} $ Javascript Framework (default JS Framework is JQuery */
    var mainTableHolderDisplayValue = this.Controls.MainTableHolder().css("display");
    if (mainTableHolderDisplayValue == "none") {
        this.Controls.MainHandle().unblink();
    }
};