Formalis.InstantMessaging.SingleSession.prototype.bindOnunloadEvent = function () {
    var obj = this;
    var $ = Formalis.ClassBase.prototype.$;
    $(window).bind('beforeunload', function () {
        $.ajax({
            type: "POST",
            async: false,
            url: obj.SiteUrl + "WebService/Service.asmx/WebRemoveSessionContext",
            data: "{ 'userName': '" + obj.UserName + "', 'sessionID': '" + obj.SessionID + "' }",
            contentType: "application/json",
            dataType: "text",
            success: function (msg) {

            }
        });
    });
};