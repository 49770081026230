/**
 * @fileOverview Online Users class definition
 * @author Mehmet Ali Koeken
 * @version 1.0
 * @name Online Users
 * @requires Formalis.Base, Formalis.InstantMessaging
 */
//TODO - MAK - Handle Error

/**
 * Online User List class manages operations related to Online Users 
 * such as keeping a user online or retrieving online user list
 * @class 
 */
Formalis.InstantMessaging.MessageWindow = function(sessionID){
    this._webServiceName = 'WebService/IM_Session.svc/';
    this._webServiceServer = Formalis.InstantMessaging.InternalWebServiceServerURL;
    this.proxy =  new Formalis.ServiceProxy(this._webServiceName,this._webServiceServer);
    /** Holds all the errors UpdateUserStatus */
    this._error_getNewMessages = new Array();
    /** Threshold for number of UpdateUserStatus errors before stopping process */
    this.threshold_error_getNewMessages = 5;
    
    this._error_syncMessages = new Array();
    this.threshold_error_syncMessages = 5;
    
    /** Holds all the errors UpdateUserStatus */
    this._error_sendNewMessage = new Array();
    /** Threshold for number of UpdateUserStatus errors before stopping process */
    this.threshold_error_sendNewMessage = 5;
    
    this._sessionID =sessionID;
    /** Default Update User Status Interval is 5000 seconds */
    this.GetNewMessagesInterval = 1000;
    this._getNewMessages_ChroneJob = new Formalis.ChroneJob("Get_New_Messages", this.GetNewMessagesInterval);
    
    
    this.SyncMessagesInterval = 1000;
    this._syncMessages_ChroneJob = new Formalis.ChroneJob("Sync_Messages", this.SyncMessagesInterval);

    this.CustomFieldListRefreshInterval = 2000;
    this._customFieldList_ChroneJob = new Formalis.ChroneJob("CustomFieldListRefresh", this.CustomFieldListRefreshInterval);
    
    this._currentUser = {
        Name:"",
        Surname: ""
    };

    /** Holds new messages to be sync by the sync messages functionality.*/
    this._messageRepository = new Array();

    // holds messages. not for sending purposes.
    this._messageBag = new Array();

    this._invitationTopic = "";
    this._isAuditor = false;
    this.sessionEnded = false;
    this.IsInited = false;
    var nowTime = new Date();
    this.LastTypingTime = nowTime.setMinutes(nowTime.getMinutes() - 1);
};

Formalis.InstantMessaging.MessageWindow.inheritsFrom(Formalis.ClassBase);