
Formalis.InstantMessaging.CustomFields.prototype.init = function () {
    };

Formalis.InstantMessaging.CustomFields.prototype.showModalCustomFields = function (
    customFieldUsage,
    customFieldFiller) {
    var $ = Formalis.ClassBase.prototype.$;
    /*
    * create a dialog
    * load aspx with post parameters
    * show modal dialog
    * do not allow closing unless form can be validated (ASP.NET validation)
    */
    // related dialog is already loaded with the relevant filler information.

    switch (customFieldUsage) {
        case 1:
            //alert('customFieldUsage: 1');
            // if there are only hidden fields in the form, auto post,
            // do not show the dialog and clear innerhtml of the dialog.
            var onlyHiddenControlsPre =
            $('select', this.Fields.modal_session_custom_field).length == 0
            && typeof Page_ClientValidate == "undefined";
            if(onlyHiddenControlsPre)
            {
                // post and clean.
                $(this.Fields.modal_session_custom_field).find('continue').trigger('click');
                $(this.Fields.modal_session_custom_field).val('');
            }
            else
            {
                $(this.Fields.modal_session_custom_field).modal('show');
            }
            break;
        case 2:
            //alert('customFieldUsage: 2');
            var onlyHiddenControlsPost =
            $('select', this.Fields.modal_session_custom_field).length == 0
            && typeof Page_ClientValidate == "undefined";

            if(onlyHiddenControlsPost)
            {
                // post and clean.
                $(this.Fields.modal_session_custom_field).find('continue').trigger('click');
                $(this.Fields.modal_session_custom_field).val('');
            }
            else
            {
                $(this.Fields.modal_session_custom_field).modal('show');
            }
            break;
    }
};

Formalis.InstantMessaging.CustomFields.prototype.bindPreSessionModal = function (
    customFieldUsage,
    customFieldFiller,
    fnLoadAndShow) {

    var obj = this;
    Formalis.InstantMessaging.MessageWindow.StaticEvent.removeAllListeners(
        Formalis.InstantMessaging.MessageWindow.Fields.ev_on_init);
    Formalis.InstantMessaging.MessageWindow.StaticEvent.addListener(
        Formalis.InstantMessaging.MessageWindow.Fields.ev_on_init,
        function (thisArg, e, scope, args) {
            fnLoadAndShow();

            // remove event to prevent being fired again.
            Formalis.InstantMessaging.MessageWindow.StaticEvent.removeAllListeners(
                Formalis.InstantMessaging.MessageWindow.Fields.ev_on_init);
        },
        this);
};

Formalis.InstantMessaging.CustomFields.prototype.bindPostSessionModal = function (
    customFieldUsage,
    customFieldFiller,
    fnLoadAndShow) {

    var obj = this;
    Formalis.InstantMessaging.MessageWindow.StaticEvent.removeAllListeners(
        Formalis.InstantMessaging.MessageWindow.Fields.ev_session_end);
    Formalis.InstantMessaging.MessageWindow.StaticEvent.addListener(
        Formalis.InstantMessaging.MessageWindow.Fields.ev_session_end,
        function (thisArg, e, scope, args) {
            fnLoadAndShow();
            // remove event to prevent being fired again.
            Formalis.InstantMessaging.MessageWindow.StaticEvent.removeAllListeners(
                Formalis.InstantMessaging.MessageWindow.Fields.ev_session_end);
        },
        this);
};

Formalis.InstantMessaging.CustomFields.prototype.setupCustomFieldDialog = function (
    // TODO: localize.
    jqSelector,
    customFieldUsage,
    customFieldFiller,
    validationGroup,
    open,
    bcccfFields) {

    var obj = this;
    var $ = Formalis.ClassBase.prototype.$;
    var modal = undefined;
    $(jqSelector)
        .find('#continue')
        .on('click',
            function() {
                var isValid = true;
                if (typeof Page_ClientValidate != "undefined") {
                    isValid = Page_ClientValidate(validationGroup);
                }

                if (isValid) {
                    obj.postCustomFields(
                        customFieldUsage,
                        customFieldFiller,
                        bcccfFields)
                    modal = $(jqSelector).modal('hide');
                }

                else
                    alert('Lütfen eksik alanları doldurunuz veya hatalı alanları düzeltiniz.');
            });
    if (open == true) {
        modal = $(jqSelector).modal('show');
    }
    if (!!modal)
    {
        modal.on('hidden.bs.modal',
        function (e) {
            if (modal.data("required")) {
                console.log(modal.data("required"));
                e.preventDefault();
            }
        });
    }
}

Formalis.InstantMessaging.CustomFields.prototype.postCustomFields = function (
    customFieldUsage,
    customFieldFiller,
    bcccfFields) {

    var $ = Formalis.ClassBase.prototype.$;

    var form;

    switch (customFieldUsage) {
        case 1:
            form = this.Controls.DialogPreSessionCustomFieldsForm();
            break;
        case 2:
            form = this.Controls.DialogPostSessionCustomFieldsForm();
            break;
    }

    var postValues = form
    //.find("input,textarea,select,hidden")
    //.not("[type=hidden][name^=__]")
    .serializeArray(); // serialize without ASP.NET viewstate and event validation.

    if (bcccfFields != null && bcccfFields != undefined) {
        for (fielddef in bcccfFields) {
            postValues.push(bcccfFields[fielddef]);
        }
    }

    postValues.push({
        name: 'op',
        value: 'save' // TODO: encrypt or encode the parameter somehow.
    })
    $.post(
        this.Fields.CustomFieldsSubmitURL,
        postValues);
}