
/**
* @description Class Controls. Uses JS Framework (default JQuery) to get Page Control
* 
*/
Formalis.InstantMessaging.Invitation.prototype.Controls = function () {
    /** @property {object} $ Javascript Framework (default JS Framework is JQuery */
    var $ = Formalis.ClassBase.prototype.$;
    var prefix = Formalis.InstantMessaging.Invitation.prototype.Fields.DOMElement_prefix;
    var obj = this;
    var that = {
        MainTableHolder: function () {
            return $('#InstantMessagingMainHolderInvitations');
        },
        MainBody: function () {
            return $('#HA_PopUp_Window_Body');
        },
        MainWrapper: function () {
            return $('#InstantMessagingMainHolderInvitations');
        },
        MainTableText: function () {
            return $('#' + "HA_Popup_Window_Header_Text");
        },
        MainHandle: function () {
            return $('#formalis_Invitations_MainHandle')
        },
        MainTableHeaderImage: function () {
            return $('#HA_PopUp_Window_Header_Image');                
        }
    };
    return that;
} ();