//Formalis.ServiceLog.prototype.GenerateLogTable = function(data){
//    var $ = Formalis.ClassBase.prototype.$;
//    if(data.Success){
//        $("#" + ${TemplateHolderDOMElementID}).setTemplate(${JSTemplate});
//        $("#" + this.UIHolder_DOMElement).setParam('TableID', "ServiceLogTableID");
//       
//        $("#" + this.UIHolder_DOMElement).processTemplate(data);
//    }
//};


