Formalis.InstantMessaging.Invitation.prototype.Validation_DisplayValidationMessages = function (validationObj) {
    if (validationObj != null
        && validationObj != undefined
        && validationObj.length > 0) {
        for (var i in validationObj) {
            //TODO - resource kullan
            $.pnotify({
                pnotify_title: 'Hata',
                pnotify_text: validationObj[i].ValidationMessage,
                pnotify_type: 'error',
                pnotify_shadow: true
            });
        }
    }

};