Formalis.ServiceLog.prototype.HTMLTemplates = {
ServiceLogDisplayTable : '<table id="{$P.TableID}">' +
'<th>{$P.LogType}</th><th>{$P.LogMessage}</th><th>{$P.LogTime}</th><th>{$P.LogServiceName}</th><th>{$P.LogDetail}</th>'+
'{#foreach $T.ServiceLogs as ServiceLog}' +
    '<tr>' +
        '<td>{$T.ServiceLog.LogType}</td>' +
        '<td>{$T.ServiceLog.LogMessage}</td>' +
        '<td>{$T.ServiceLog.LogTime}</td>' +
        '<td>{$T.ServiceLog.LogServiceName}</td>' +
        '<td>{$T.ServiceLog.LogDetail}</td>' +
    '</tr>' +
'{#/for}' +
'</table>'
};