Formalis.InstantMessaging.OnlineUsers.prototype.bindStartSessionClickEvent = function() {
    /** @property {object} $ Javascript Framework (default JS Framework is JQuery */
    var $ = Formalis.ClassBase.prototype.$;
    var obj = this;
    $("." + this.Fields.userMessageRequestClass).off('click.userMessageRequestClass');
    $("." + this.Fields.userMessageRequestClass).on('click.userMessageRequestClass', function(e) {
        e.preventDefault();
        obj.handleStartNewInvitation($(this));
    });
};
Formalis.InstantMessaging.OnlineUsers.prototype.bindDraggableEvent = function() {
    /** @property {object} $ Javascript Framework (default JS Framework is JQuery */
    var $ = Formalis.ClassBase.prototype.$;
    var obj = this;
    var inTableHeight = 115;
    this.Controls.MainWrapper().draggable({
        handle : "#" + obj.Fields.DraggableHandle,
        stop : function(event, ui) {
            Formalis.Utils.RecordElementPositionToCookie(obj.Controls.MainWrapper().attr("id"));
        }
    });
    var objectState = Formalis.Utils.ReadElementPositionToCookie(this.Controls.MainWrapper().attr("id"));
    if(objectState != null) {
        this.Controls.MainWrapper().css("top", objectState.top);
        this.Controls.MainWrapper().css("left", objectState.left);
    }
    var objectStateDisplay = Formalis.Utils.ReadElementDisplayValueToCookie(this.Controls.MainTableHolder().attr("id"));
    if(objectStateDisplay != null
        && objectStateDisplay.display != null
        && objectStateDisplay.display != undefined){
        obj.Controls.MainTableHolder().css("display",objectStateDisplay.display);
        //AdjustMainWrapperHeight();
        if (obj.Controls.MainTableHolder().css("display") == "none") {
            var height = obj.Controls.MainWrapper().height();
            obj.Controls.MainWrapper().height(height - inTableHeight);
        }
    }

    this.Controls.MainWrapper().corner();

    //this.Controls.MainTableText().css('cursor', 'pointer');
    this.Controls.MainWrapper().css('z-index', '34598');
    this.Controls.MainTableHeaderImage().off("click.MainTableHolderOnlineUsers");
    this.Controls.MainTableHeaderImage().on("click.MainTableHolderOnlineUsers", function (e) {
        obj.Controls.MainTableHolder().toggle();
        Formalis.Utils.RecordElementDisplayValueToCookie(obj.Controls.MainTableHolder().attr("id"));
        AdjustMainWrapperHeight();
    });
    function AdjustMainWrapperHeight() {
        if(obj.Controls.MainTableHolder().css("display") == "none") {
            var height = obj.Controls.MainWrapper().height();
            obj.Controls.MainWrapper().height(height - inTableHeight);
        } else {
            var height = obj.Controls.MainWrapper().height();
            obj.Controls.MainWrapper().height(height + inTableHeight);
        }
    }
};
