/**
 * @fileOverview Holds Chrone Job related functions and properties
 * @name Chrone Job Definition
 * @author Formalis\Mehmet Ali Köken
 * @author KRK
 * @version 1.0
 */


/**
 * Initiates Chrone Job.
 * @param {string} name ChroneJob Name
 * @param {number} timeInterval Time Interval in Miliseconds.
 * @constructor
 */
Formalis.ChroneJob = function (name, timeInterval) {
    this.ChroneJobTimeInterval = timeInterval;
    this.ChroneJobInvervalObj = null;
    var eventSuffix = "ChroneJobEvent_";
    this.EventName = name + eventSuffix;
    this.TickEventName = this.EventName + "_Tick";
    this.EventHandlers = {};
    this.MaxTickCount = 0;
    this.TickCount = 0;
    this._isActive = false;
};

Formalis.ChroneJob.inheritsFrom(Formalis.ClassBase);
Formalis.ChroneJob.prototype.Event = new Formalis.Event();

Formalis.ChroneJob.prototype.addTickHandler = function (handler, scope){
    addTickHandler(handler,scope, 0);
};

Formalis.ChroneJob.prototype.addTickHandler = function (handler, scope, maxTickCount) {
    var obj = this;
    try {
        this.MaxTickCount = maxTickCount;
        this.Event.addListener(this.TickEventName, handler, scope);
    } catch (e) {
        obj.ErrorHandler(e);
    }
};

/**
 * Removes  Tick Event Handler
 * @param {function} Tick Event Handler Function
 * @param {object} Tick Event Handler Scope
 */
Formalis.ChroneJob.prototype.removeTickHandler = function (handler, scope) {
    var obj = this;
    try {
        this.Event.removeListener(this.TickEventName, handler);
    } catch (e) {
        obj.ErrorHandler(e);
    }
};

/**
 * Starts Chrone Job. Adds event listener and sets interval.
 */
Formalis.ChroneJob.prototype.start = function () {
    var obj = this;
    try {
        this.ChroneJobInvervalObj = setInterval(function () {
            obj.eventWrapper();
        }, this.ChroneJobTimeInterval);
        this._isActive = true;
    } catch (e) {
        obj.ErrorHandler(e);
    }
};

Formalis.ChroneJob.prototype.eventWrapper = function(){

    if(this.MaxTickCount> 0 && this.TickCount >= this.MaxTickCount)
    {
        this.stop();
    }else
    {
        this.TickCount++;
        this.Event.fire(this.TickEventName);
    }
};

/**
 * Stops Chrone Job.Clears the interval and removes tick event
 */
Formalis.ChroneJob.prototype.stop = function () {
    var obj = this;
    try {
        this.clear();
    } catch (e) {
        obj.ErrorHandler(e);
    }
};

/**
 * Clears the interval and removes tick event. Is Active property is set to false;
 */
Formalis.ChroneJob.prototype.clear = function () {
    var obj = this;
    try {
        clearInterval(this.ChroneJobInvervalObj);
        this.Event.removeAllListeners(this.TickEventName);
        this._isActive = false;
    } catch (e) {
        obj.ErrorHandler(e);
    }

};

/**
 * Checks if chrone job is active.
 * @returns Returns true if job is active, otherwise returns false.
 */
Formalis.ChroneJob.prototype.isActive = function () {
    return this._isActive;
};

/**
 * Default Error Handler function
 */
Formalis.ChroneJob.prototype.ErrorHandler = function (ex) {
    //TODO - MAK - Implement Error handler
    throw ex;
};
