//Formalis.ServiceLogs.prototype.Controls = function () {
//    var $ = Formalis.ClassBase.prototype.$;
//    var that = {
//        MessageHolder: function () { return $('#RestRequestMessagesHolder'); },
//        Button_GetMessages: function () { return $('#btnRestRequestMessages'); },
//        Button_SavePublicMessage: function () { return $('#btnRestRequestPublicMessageSender'); },
//        Text_RestRequestMessageSender: function () { return $('#txtRestRequestMessageSender'); }
//    }
//    return that;
//}() ;

