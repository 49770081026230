/**
 * Handles UpdateOnlineStatusDao events.
 * If number of caught errors during update online status exceeds threshold,
 * all the OnlineUser class events are stopped resulting in an end in page activity for the class.
 * @param {object} errorObject Error Object
 * @returns {void} void
 */
Formalis.InstantMessaging.Invitation.prototype.Handle_GetNewInvitations_Error = function(errorObject){
    this._error_getNewInvitation.push(errorObject);
    this.CheckAndHandleConnectionInstability();

};

Formalis.InstantMessaging.Invitation.prototype.Handle_CheckInvitationStatus_Error = function(errorObject){
    this._error_checkInvitationStatus.push(errorObject);
    this.CheckAndHandleConnectionInstability();

};

Formalis.InstantMessaging.Invitation.prototype.Handle_cancelPendingInvitation_Error = function(errorObject){
    this._error_checkInvitationStatus.push(errorObject);
    this.CheckAndHandleConnectionInstability();
};

Formalis.InstantMessaging.Invitation.prototype.CheckAndHandleConnectionInstability = function(){

    if(this._error_getNewInvitation.length>this.threshold_error_getNewInvitation){
        //end page activity.
        this.stopAllProcesses();

    //Display error text
    }
    if(this._error_checkInvitationStatus.length>this.threshold_error_checkInvitationStatus){
        //end page activity.
        this.stopAllProcesses();
    //Display error text
    }
    if(this._error_business.length>this.threshold_error_business){
        //end page activity.
        this.stopAllProcesses();
    //Display error text
    }
};

Formalis.InstantMessaging.Invitation.prototype.logError = function () {
    var errorMessage = "";
    errorMessage += " * Get New Invitation Errors : " + Formalis.ErrorHandler.ErrorListToString(this._error_getNewInvitation);
    errorMessage += " * Check Invitation Status Errors :  " + Formalis.ErrorHandler.ErrorListToString(this._error_checkInvitationStatus);
    errorMessage += " * Business Errors :  " + Formalis.ErrorHandler.ErrorListToString(this._error_business);
    var serviceLogObj = new Formalis.ServiceLog(Formalis.InstantMessaging.ServiceLogWebServiceBaseURL);
    serviceLogObj.saveLog("Client Side - Instant Messaging - Invitation Module", errorMessage);
};


Formalis.InstantMessaging.Invitation.prototype.HandleBusinessError = function (errorObject) {
    this._error_business.push(errorObject);
    this.CheckAndHandleConnectionInstability();
};

/**
 * Handles UpdateOnlineStatusDao events.
 * If number of caught errors during update online status exceeds threshold,
 * all the OnlineUser class events are stopped resulting in an end in page activity for the class.
 * @param {object} errorObject Error Object
 * @returns {void} void
 */
Formalis.InstantMessaging.Invitation.prototype.Handle_CheckAuthorization_Error = function(errorObject){
    this.stopAllProcesses();
    this.hideInvitationsPanel();
};
Formalis.InstantMessaging.Invitation.prototype.Handle_CheckSendInvitationAuthorization_Error = function (errorObject) {
    this.stopAllProcesses();
//this.Controls.LiveSupportButton().hide();
};
