/**
 * Updates users online status
 * @param {integer} UserID User's ID
 * @returns {void} void
 */
Formalis.ServiceLog.prototype.saveLogDAO = function(serviceLog){
    var obj = this;
    
    this.proxy.InvokeJson("ServiceLog/","POST",serviceLog,null,function(data){
        if(data != null && data.Success===true){
            obj.Handler_SaveLog(data);
        }else{
            obj.Handle_SaveLog_Error(data);
        }
    },function(err){
        obj.Handle_SaveLog_Error(err);
    },true,function(){});
};



Formalis.ServiceLog.prototype.SaveErrorDAO = function(serviceErrorLogObj){
    var obj = this;
    this.proxy.InvokeJSONP("ServiceLog","POST",{
        serviceLog:serviceErrorLogObj
    },new Array('Error'),function(data){
        //Success Function
        obj.Handler_SaveError(data);
    },function(e){
        obj.ErrorHandler(e);
    },true,null,null);
};

Formalis.ServiceLog.prototype.GetAllLogsDAO = function(){
    var obj = this;
    this.proxy.InvokeJSONP("ServiceLog","GET",null,new Array('Error'),function(data){
        //Success Function
        if(data == null){
            obj.ErrorHandler("Get All Logs Dao - Data Null");
        }else if (data.Success){
            obj.Handler_GetAllLogs(data);
        }
    },function(e){
        obj.ErrorHandler(e);
    },true,null,null);
};


