Formalis.InstantMessaging.Invitation.prototype.HTMLTemplates = {
    CreateNewInvitationsTable:
        '{#foreach $T.Invitations as invitation}' +
            '<div class="live-help-requests clearfix">' +
                '<span class="live-help-requests-cat">{$T.invitation.InvitationTopic}</span>' +
                '<span class="live-help-requests-name">{$T.invitation.Invitor.Name} {$T.invitation.Invitor.Surname}</span>' +
                '<span class="live-help-requests-time timer"><label time="{$T.invitation.ElapsedTime}">{$T.invitation.FormattedElapsedTime}</label></span>' +
                '<a href="#" class="btn btn-helpAlive invitation_Invitation_startSession_link" invitationId="{$T.invitation.InvitationID}" windowKey="{$T.invitation.Key}">Kabul et</a>' +
            '</div>' +
        '{#/for}',
    Invitations_MainStructure:
    '<div id="{$P.Prefix}Invitations_NoNewInvitations">{$P.Invitations}</div>',
    Invitations_NoNewInvitations:
        '<div id="{$P.Prefix}Invitations_NoNewInvitations">{$P.Invitations}</div>'
};