/**
 * Service Log Constructor
 * @param {integer} UIHolder_DOMElement The id of the employee.
 * @param {string} [serviceUrl] Service Url.
 * @param {string} [server] Server Address
 */

Formalis.ServiceLog = function (WebServiceServer) {
    this._webServiceName = '/WebService/IM_ServiceLog_Public.svc/';
    this._webServiceServer = "";
    this._webServiceServer = WebServiceServer;
    this.proxy = new Formalis.ServiceProxy(this._webServiceName, this._webServiceServer);
    this._error_SaveLog = new Array();
    this.threshold_error_SaveLog = 5;
};
