/**
* @fileOverview Formalis JS Utility Methods
* @name Formalis JS Utilities
* @author Formalis\Mehmet Ali Köken
* @version 1.0
*/

/**
* @class Utils Class
*/
Formalis.Utils = {

    };

// console.log yerine kullanılır. IE'de patlamaz.
$.log = function (message) {

    var logMessage;
    if(typeof(message) == 'string')
    {
        logMessage = message;
    }
    else
    {
        logMessage = Formalis.Utils.objectToString(message, 10, 0);
    }

    if (window.console && console.log) {
        console.log('Log: ' + logMessage);
    }

    if (message && message.stack) {
        console.log(message.stack);
    }

    return this;
}

/**
* Checks if the given string is empty
* @param {string} Input String
* @returns {boolean} returns true if string is empty, false otherwise.
*/

String.prototype.IsEmpty = function () {
    if (null == this || "" == this) {
        return true;
    }
    return false;
};

/**
* Checks if a string contains given text value
* @param {string} text Input Text
* @returns {boolean} returns true if string contains value, false otherwise.
*/
String.prototype.Contains = function (text) {
    if (this.indexOf(text) != -1) {
        return true;
    }
    else return false;
}

/**
* Replaces all StringToFind with stringToReplace
* @param {string} stringToFind key, string to be replaces
* @param {string} stringToReplace Value, Replace Value
*/
String.prototype.replaceAll = function (stringToFind, stringToReplace) {
    var temp = this;
    var index = temp.indexOf(stringToFind);
    while (index != -1) {
        temp = temp.replace(stringToFind, stringToReplace);
        index = temp.indexOf(stringToFind);
    }
    return temp;
}

Formalis.Utils.keepScrollDown = function (domElement) {
    domElement.mCustomScrollbar("scrollTo", ['bottom', null]);
};

/**
* Simulates button click on pressing enter button on a given textbox.
*
*/
Formalis.Utils.simulateButtonClickOnTextBoxEnterKeyPress = function (textID, buttonID) {
    var $ = Formalis.ClassBase.prototype.$;
    $(document).ready(function () {
        var textElement = $('#' + textID);
        textElement.off('keypress.simulateButtonClickOnTextBoxEnterKeyPress');
        textElement.on('keypress.simulateButtonClickOnTextBoxEnterKeyPress', function (e) {
            var code = (e.keyCode ? e.keyCode : e.which);
            if (code == 13) {
                e.preventDefault();
                $('#' + buttonID).click();
                textElement.trigger('keyup');
            }
        });
    });
};

Formalis.Utils.simulateLinkButtonClickOnTextBoxEnterKeyPress = function (textID, buttonID) {
    var $ = Formalis.ClassBase.prototype.$;
    $(document).ready(function () {
        $('#' + textID).off('keypress.simulateButtonClickOnTextBoxEnterKeyPress');
        $('#' + textID).on('keypress.simulateButtonClickOnTextBoxEnterKeyPress', function (e) {
            var code = (e.keyCode ? e.keyCode : e.which);
            if (code == 13) {
                e.preventDefault();
                eval($('#' + buttonID).attr('href'));
            }
        });
    });
};

Formalis.Utils.limitTextInput = function (textID, characterLimit) {
    var $ = Formalis.ClassBase.prototype.$;
    $(document).ready(function () {
        var textElement = $('#' + textID);
        textElement.off('keypress.LimitTextBoxLengthEnterKeyPress');
        textElement.on('keypress.LimitTextBoxLengthEnterKeyPress', function (e) {
            var text = textElement.val();
            if (text != null && text.length > characterLimit) {
                value = text.substr(0, characterLimit);
                $('#' + textID).val(value);
            }
        });
    });
};

Formalis.Utils.decimalInput = function (jqSelector) {
    var $ = Formalis.ClassBase.prototype.$;
    $(document).ready(function () {
        $(jqSelector).numeric();
    });
};

Formalis.Utils.integerInput = function (jqSelector) {
    var $ = Formalis.ClassBase.prototype.$;
    $(document).ready(function () {
        $(jqSelector).numeric(false);
    });
};

Formalis.Utils.registerSelectAllOnFocus = function (jqSelector) {
    var $ = Formalis.ClassBase.prototype.$;
    $(document).ready(function () {
        $(jqSelector).focus(function () {
            // Select field contents
            this.select();
        });
    });
};

/**
* Checks if input parameter is an object and if so appends objet values to
*/
Formalis.Utils.objectToString = function (obj, limit, depth) {
    var retVal = "";
    if (depth == null
        || depth == undefined) {
        depth = 0;
    }
    if (limit == null
        || limit == undefined) {
        limit = 0;
    }
    if (obj != null
        && obj != undefined
        && typeof (obj) == "object"
        ) {
        for (var i in obj) {
            if (obj[i] != null && obj[i] != undefined) {
                retVal += " - key : " + i + " value : ";
                if (depth < limit) {
                    depth++;
                    retVal += Formalis.Utils.objectToString(obj[i], depth);
                }
                retVal += Formalis.Utils.arrayToString(obj[i]);
                retVal += Formalis.Utils.simpleTypeToString(obj[i]);
            }
        }
    }
    return retVal;
};
/**
* Checks if the input parameter is an array, and if so appends array values to retVal string.
*/
Formalis.Utils.arrayToString = function (input) {
    var retVal = "";
    if (input != null
        && input != undefined
        && input instanceof Array) {
        if (input != null && input.length > 0) {
            for (i = 0; i < input.length; i++) {
                //object to string checks if the input is object or not.
                retVal += " - ";
                retVal += Formalis.Utils.objectToString(input[i]);
                retVal += Formalis.Utils.arrayToString(input[i]);
                retVal += Formalis.Utils.simpleTypeToString(input[i]);
            }
        }
    }
    return retVal;
};
/**
* Checks if the input parameter is a simple type (not function, object or array)
* and appends value to retval.
*/
Formalis.Utils.simpleTypeToString = function (input) {
    var retVal = "";
    if (input != null
        && input != undefined
        && typeof (input) != "object"
        && typeof (input) != "function"
        && !(input instanceof Array)
        ) {
        retVal += "" + input;
    }
    return retVal;
};


Formalis.Utils.RecordElementPositionToCookie = function (elementID) {
    var $ = Formalis.ClassBase.prototype.$;
    var aReturn = {};
    var element = $("#" + elementID);
    aReturn["left"] = element.css("left");
    aReturn["top"] = element.css("top");
    var json = $.toJSON(aReturn);
    Formalis.Cookie.SetCookie(elementID + "_REPTC", json, 100, "/");
};

Formalis.Utils.ReadElementPositionToCookie = function (elementID) {
    var $ = Formalis.ClassBase.prototype.$;
    var json = Formalis.Cookie.GetCookie(elementID + "_REPTC");
    if (json == null) return null;
    var aReturn = {};
    //var element = jQuery("#" + elementID);
    aReturn = $.evalJSON(json)
    return aReturn;
};

Formalis.Utils.RecordElementDisplayValueToCookie = function (elementID) {
    var $ = Formalis.ClassBase.prototype.$;
    var aReturn = {};
    var element = $("#" + elementID);
    aReturn["display"] = element.css("display");
    //aReturn["top"] = element.css("top");
    var json = $.toJSON(aReturn);
    Formalis.Cookie.SetCookie(elementID + "_REPTC_Display", json, 100, "/");
};

Formalis.Utils.ReadElementDisplayValueToCookie = function (elementID) {
    var $ = Formalis.ClassBase.prototype.$;
    var json = Formalis.Cookie.GetCookie(elementID + "_REPTC_Display");
    if (json == null) return null;
    var aReturn = {};
    //var element = jQuery("#" + elementID);
    aReturn = $.evalJSON(json)
    return aReturn;
};

Formalis.Utils.disableBackspaceNavigation = function(document){
    $(document).keydown(function(e) {
        var element = e.target.nodeName.toLowerCase();
        if (element != 'input' && element != 'textarea') {
            if (e.keyCode === 8) {
                return false;
            }
        }

        return true;
    });
};

Formalis.Utils.disableF5 = function(document){
    $(document).keydown(function(e) {

        if(e.keyCode == 116 /* F5 */)
        {
            return false;
        }

        return true;
    });
};

Formalis.Utils.linkify = function (text) {
    var inputText = text;
    //URLs starting with http://, https://, or ftp://
    var replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    var replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

    //URLs starting with www. (without // before it, or it'd re-link the ones done above)
    var replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

    //Change email addresses to mailto:: links
    //chrome'da oturumu kapattigi icin mailto secenegi iptal edildi
    /*
    var replacePattern3 = /(\w+@[a-zA-Z_]+?\.[a-zA-Z]{2,6})/gim;
    replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');
    */
    return replacedText;
};

Formalis.Utils.escapeHTML = function (txt) {
    return txt.replace(/&/g, '&amp;').replace(/>/g, '&gt;').replace(/</g, '&lt;');
};

Formalis.Utils.unescapeHTML = function (txt) {
    return txt.replace('&amp;', '&').replace('&gt;', '>').replace('&lt;', '<');
};