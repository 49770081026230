/**
 * @fileOverview  Instant Messaging Base Function 
 * @name Javascript Base File - Namespace declaration
 * @author Formalis\Mehmet Ali Koeken    
 * @version 1.0
 */

/**
 * @namespace Formalis.InstantMessaging namespace declaration
 */



Formalis.InstantMessaging.Fields = {
    pnlMainErrorTemplateID : "InstantMessaging_Main"
    
};
