//DateRange
$(function () {
    //var start = moment().subtract(30, 'days');
    //var end = moment();
    function cb(start, end) {
        $('#daterange span').html(start.format('DD.MM.YYYY') + ' - ' + end.format('DD.MM.YYYY'));
        $("#" + $('#daterange').data("hidden")).val(start.format('DD.MM.YYYY') + '+' + end.format('DD.MM.YYYY'));
    }

    //cb(start, end);

    $('#daterange').daterangepicker({
        opens: "center",
        startDate: moment().subtract(30, 'days'),
        ranges: {
            'Bugün': [moment(), moment()],
            'Dün': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
            'Son 7 gün': [moment().subtract(6, 'days'), moment()],
            'Son 30 gün': [moment().subtract(30, 'days'), moment()],
            'Bu ay': [moment().startOf('month'), moment()],
            'Geçen ay': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
        },
        locale: {
            "format": "MM/DD/YYYY",
            "separator": " - ",
            "applyLabel": "Tamam",
            "cancelLabel": "Vazgeç",
            "fromLabel": "From",
            "toLabel": "To",
            "customRangeLabel": "Özel",
            "daysOfWeek": [
                "Pa",
                "Pt",
                "Sa",
                "Ça",
                "Pe",
                "Cu",
                "Ct"
            ],
            "monthNames": [
                "Ocak",
                "Şubat",
                "Mart",
                "Nisan",
                "Mayıs",
                "Haziran",
                "Temmuz",
                "Ağustos",
                "Eylül",
                "Ekim",
                "Kasım",
                "Aralık"
            ],
            "firstDay": 1
        }
    }, cb);

});
