/**
* @description Class Controls. Uses JS Framework (default JQuery) to get Page Control
*
*/
Formalis.InstantMessaging.MessageWindow.prototype.Controls = function () {
    /** @property {object} $ Javascript Framework (default JS Framework is JQuery */
    var $ = Formalis.ClassBase.prototype.$;
    var that = {
        MessagesHolder: function () {
            return $('#MessageWindow_Messages_Holder');
        },
        ScrollBarHolder: function() {
            return $('#chatScroll');
        }, SendMessageButton: function () {
            return $('#btnMessageWindow_MessageSend');
        }, sendMessageIcons: function() {
            return $('#textarea-icons');
        },
        SelectTemplateButton: function () {
            return $('#btnMessageWindow_SelectTemplate');
        },
        txtMessageBox: function () {
            return $('#txtMessageWindow_NewMessage');
        },
        OperatorWaitingHolder: function () {
            //Similar value exists in Fields.
            return $('.MessageWindow_OperatorWaitingClass');
        },
        SendEmailButton: function () {
            return $('#btnSendEmail');
        },
        EndSessionButton: function () {
            return $('#btnMessageWindow_MessageEndSession');
        },
        UserCustomFieldList: function () {
            return $('#MessagingWindow_UserCustomFieldList');
        },
        ConvertToIssueButton: function () {
            return $('#btnMessageWindow_ConvertToRecord');
        },
        ConvertToIssueHolder: function () {
            return $('#MessageWindow_Body_Buttons_ConvertToRecord');
        },
        WritingMessageHolder: function () {
            return $('#lblWritingMessage');
        },
        GoOfflineButton: function() {
            return $('#btMessageWindow_GoOffline');
        },
        ChatScroll: function() {
            return $("#chatScroll");
        },
        CustomerCustomFieldInfoHolder: function() {
            return $("#UserDetail_Wrapper");
        }
    }
    return that;
} ();

