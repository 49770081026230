/**
 * Updates users online status
 * @param {integer} UserID User's ID
 * @returns {void} void
 */
Formalis.InstantMessaging.MessageWindow.prototype.syncMessagesDAO = function (messageList, isAuditor) {
    var obj = this;
    var isWriting = '0';
    var todayDate = new Date();
    var diff = new Date(todayDate - this.LastTypingTime);
    if (!obj.failCount) {
        obj.failCount = {};
    }

    if(diff.getMinutes() == 0 && diff.getSeconds()<=1){
        isWriting = '1';
    }

    var proxySuffix = new Array("s=" + this._sessionID, Formalis.InstantMessaging.CurrentUser.SessionUserType, isWriting, 'sync');//
    //if (isAuditor) {
    //    proxySuffix.push("auditor");
    //}
    this.proxy.InvokeJson("message", "POST", messageList, proxySuffix, function (data) {
        if (data != null && data.Success === true) {
            Formalis.InstantMessaging.HandleDAOResponse(data);
            obj.Handler_SyncMessages(data);
            if (data.StopProcess)
                obj.stopAllProcesses();
        } else {
            obj.Handle_SyncMessages_Error(data);
            obj.failCount[obj._sessionID] = obj.failCount[obj._sessionID] ? obj.failCount[obj._sessionID] + 1 : 0;
            if(obj.failCount[obj._sessionID] > 10)
                obj.stopAllProcesses();
        }

        if (data && data.CustomFields) {
            obj.appendCustomerCustomFieldInfo(data.CustomFields);
        }
    }, function (err) {
        obj.Handle_SyncMessages_Error(err);
        obj.failCount[obj._sessionID] = obj.failCount[obj._sessionID] ? obj.failCount[obj._sessionID] + 1 : 0;
        if(obj.failCount[obj._sessionID] > 10)
            obj.stopAllProcesses();
    }, true, Formalis.InstantMessaging.SetAuthHeader);
    //clear object list
    //obj._messageRepository = new Array();
};


/**
 * Updates users online status
 * @param {integer} UserID User's ID
 * @returns {void} void
 */
Formalis.InstantMessaging.MessageWindow.prototype.getNewMessagesDAO = function () {
    var obj = this;
    this.proxy.InvokeJson("message", "GET", null, new Array("s=" + this._sessionID, 'new'), function (data) {
        if (data != null && data.Success === true) {
            Formalis.InstantMessaging.HandleDAOResponse(data);
            obj.Handler_GetNewMessages(data);

        } else {
            obj.Handle_GetNewMessages_Error(data);
        }
    }, function (err) {
        obj.Handle_GetNewMessages_Error(err);
    }, true, Formalis.InstantMessaging.SetAuthHeader);
};


/**
 * Updates users online status
 * @param {integer} UserID User's ID
 * @returns {void} void
 */
Formalis.InstantMessaging.MessageWindow.prototype.sendNewMessageDAO = function (messageText) {
    var obj = this;
    this.proxy.InvokeJson("message", "POST", {
        message: {
            MessageText: messageText
        }
    }, new Array("s=" + this._sessionID), function (data) {
        if (data == null || data.Success !== true) {
            Formalis.InstantMessaging.HandleDAOResponse(data);
            obj.Handle_SendNewMessage_Error(data, messageText);
        }
    }, function (err) {
        obj.Handle_SendNewMessage_Error(err, messageText);
    }, true, Formalis.InstantMessaging.SetAuthHeader);
};

/**
 * Updates users online status
 * @param {integer} UserID User's ID
 * @returns {void} void
 */
Formalis.InstantMessaging.MessageWindow.prototype.endSessionDAO = function () {
    var obj = this;
    this.proxy.InvokeJson("session", "DELETE", null, new Array("s=" + this._sessionID, Formalis.InstantMessaging.CurrentUser.SessionUserType), function (data) {
        if (data == null || data.Success !== true) {
            Formalis.InstantMessaging.HandleDAOResponse(data);
            obj.Handle_EndSession_Error(data);
        }
    }, function (err) {
        obj.Handle_EndSession_Error(err);
    }, true, Formalis.InstantMessaging.SetAuthHeader);
};

//Gerekli olmayabilir
Formalis.InstantMessaging.MessageWindow.prototype.goOfflineDAO = function() {
    var obj = this;
    this.proxy.InvokeJson("offline", "GET", null, new Array(), function(data) {
        if (data == null || data.success !== true) {
            Formalis.InstantMessaging.HandleDAOResponse(data);
            obj.Handle_EndSession_Error(data);
        }
    }, function (err) {
        if (!err) {
            $.pnotify({
                pnotify_title: obj.Resource.Lang.GoOfflineTitle,
                pnotify_text: obj.Resource.Lang.GoOfflineSuccessText,
                pnotify_delay: 5000,
                pnotify_history: false,
            });
            return;
        }
        obj.Handle_EndSession_Error(err);
    }, true, Formalis.InstantMessaging.SetAuthHeader);
};
/**
* Updates users online status
* @param {integer} UserID User's ID
* @returns {void} void
*/
Formalis.InstantMessaging.MessageWindow.prototype.convertToIssueDAO = function () {
    var obj = this;
    this.proxy.InvokeJson("session", "GET", null, new Array("s=" + this._sessionID, "history"), function (data) {
        if (data == null || data.Success !== true) {
            Formalis.InstantMessaging.HandleDAOResponse(data);
            obj.Handle_ConvertToIssue_Error(data);
        } else {
            obj.Handler_ConvertToIssue();
        }
    }, function (err) {
        obj.Handle_ConvertToIssue_Error(err);

    }, true, Formalis.InstantMessaging.SetAuthHeader);
};

Formalis.InstantMessaging.MessageWindow.prototype.sendEmailDAO = function () {
    var Data = '{"mailAdress":"' + Formalis.InstantMessaging.CurrentUser.Email + '","sessionID":"' + this._sessionID + '"}';

    $.ajax(
    {
        type: "POST",
        url: location.protocol + "//" + document.domain + "/WebService/IM_ExternalSession.svc/MessagesSendEmail",
        contentType: "application/json",
        data: Data,
        beforeSend: Formalis.InstantMessaging.SetAuthHeader,
        dataType: "json",
        processdata: true,
        success: OnSendMailSuccessJson,
        error: OnSendMailErrorJson
    });

    function OnSendMailSuccessJson(data) {
        Formalis.InstantMessaging.MessageWindow.prototype.sendEmail();
    }
    function OnSendMailErrorJson() {
        Formalis.InstantMessaging.MessageWindow.prototype.sendEmailError();
    }


};
