/**
 * @fileOverview Activity Monitor monitors page window for mouse activity. 
 * @name Activity Monitor
 * @author Formalis\Mehmet Ali Köken
 * @version 1.0
 * Requires Formalis.Events class.
 */

/**
 * Activity monitor is a generic class that monitors mouse activity in an open window.
 * Constructor initiates variables and sets EVENT base for the class. 
 * @class  Activity Monitor Class Definition and Constructor.  
 * @param {number} _IntervalTime_Verification The interval time for activity verificiatiın
 * @param {number} _MAX_WITHOUT_ACTIVITY maximum time without activity. If this time is exceeded, inactivity is fired.
 * @param {obj} JS Framework Obj
 */
Formalis.ActivityMonitor = function (_IntervalTime_Verification, _MAX_WITHOUT_ACTIVITY, JSFramework) {
    /* Set Defaults */
    if (_IntervalTime_Verification == null || _IntervalTime_Verification == undefined) {
        this.IntervalTime_Verification = 5000;
    } else {
        this.IntervalTime_Verification = _IntervalTime_Verification;
    }

    if (_MAX_WITHOUT_ACTIVITY == null || _MAX_WITHOUT_ACTIVITY == undefined) {
        this.MAX_WITHOUT_ACTIVITY = 10000;
    } else {
        this.MAX_WITHOUT_ACTIVITY = _MAX_WITHOUT_ACTIVITY;
    }
    if (JSFramework == null || JSFramework == undefined) {
        this.$ = $;
    } else {
        this.$ = JSFramework;
    }

    this.lastActivity = {};
    this.ChroneJob = new Formalis.ChroneJob("ActivityMonitor", this.IntervalTime_Verification);
    this.EventHandlers = {};

    this.ActivityStart_EventName = "ActivityMonitor_ActivityStart";
    this.ActivityStop_EventName = "ActivityMonitor_ActivityStop";
};

Formalis.ActivityMonitor.prototype.Event = new Formalis.Event();
