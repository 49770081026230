/**
 * @fileOverview Holds Formalis Enum Definition
 * @name Formalis.Enum
 * @author Formalis\Mehmet Ali Köken
 * @version 1.0
 */

/**
 * @class Exception class definition.
 */
Formalis.Exception = function (message,description,type) {
    /** Exception Message @type string */
    this.Message = message;
    this.Description = description;
    this.Type = type;
}