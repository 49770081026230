/**
 * @fileOverview Online Users class definition
 * @author Mehmet Ali Koeken
 * @version 1.0
 * @name Online Users
 * @requires Formalis.Base, Formalis.InstantMessaging
 */

/**
 * Online User List class manages operations related to Online Users 
 * such as keeping a user online or retrieving online user list
 * @class 
 */


Formalis.InstantMessaging.Invitation = function (invitationID) {
    this._webServiceName = 'WebService/IM_Invitation_Public.svc/';
    this._webServiceServer = Formalis.InstantMessaging.InternalWebServiceServerURL;
    this.proxy = new Formalis.ServiceProxy(this._webServiceName, this._webServiceServer);
    /** Holds all the errors UpdateUserStatus */
    this._error_getNewInvitation = new Array();
    /** Threshold for number of UpdateUserStatus errors before stopping process */
    this.threshold_error_getNewInvitation = 5;

    /** Holds all the errors UpdateUserStatus */
    this._error_business = new Array();
    /** Holds all the errors UpdateUserStatus */
    this.threshold_error_business = new Array();

    /** Holds all the errors UpdateUserStatus */
    this._error_checkInvitationStatus = new Array();
    /** Threshold for number of UpdateUserStatus errors before stopping process */
    this.threshold_error_checkInvitationStatus = 5;

    /** Default Get New Invitations Interval is 2000 seconds */
    this.GetNewInvitationsInterval = 5000;
    this._getNewInvitations_ChroneJob = new Formalis.ChroneJob("Get_New_Invitations", this.GetNewInvitationsInterval);

    /** Default Update User Status Interval is 2000 seconds */
    this.CheckInvitationStatusInterval = 5000;
    this._checkInvitationStatus_ChroneJob = new Formalis.ChroneJob("Check_Invitation_Status", this.CheckInvitationStatusInterval);
    this._invitationID = invitationID;

    this._invitationList = {};
    this.LiveSupportRequestButtonID = "";
    this.EncryptedUserName = "";
    this._authorizedToGetInvitationList = false;
    this.inTableHeight = 0;
    this._invitationTopic = '';

    /** Used in cancelling a pending invitation. */
    this.isInvitationPending = true;
};

Formalis.InstantMessaging.Invitation.inheritsFrom(Formalis.ClassBase);