/**
 * @fileOverview CookieClass Definition
 * @name Class Base Class Definition
 * @author Formalis\Mehmet Ali Köken
 * @version 1.0
 */

/**
 * @class Cookie class includes functionality for managing cookies.
 */
Formalis.Cookie = {};

/**
 * [Internal Function] Gets Cookie Value from given offset. 
 * @param {string} offset Cookie Offset Value
 * @returns Cookie Value
 * @private
 */
Formalis.Cookie.GetCookieVal = function (offset) {

    var endstr = document.cookie.indexOf(";", offset);
    if (endstr == -1) {
        endstr = document.cookie.length;
    }
    return unescape(document.cookie.substring(offset, endstr));
};

/**
 * Gets Cookie Value by given name parameter.
 * @param {string} name Cookie Name 
 * @returns Cookie Value
 */
Formalis.Cookie.GetCookie = function (name) {
    try {
        var arg = name + "=";
        var alen = arg.length;
        var clen = document.cookie.length;
        var i = 0;
        while (i < clen) {
            var j = i + alen;
            if (document.cookie.substring(i, j) == arg) {
                return Formalis.Cookie.GetCookieVal(j);
            }
            i = document.cookie.indexOf(" ", i) + 1;
            if (i == 0) break;
        }
    } catch (e) {
        Formalis.Cookie.ErrorHandler(e);
    }
    return null;
}

/**
 * @description Deletes Cookie.
 * @param {string} name Cookie Name
 * @param {string} path Cookie Path
 * @param {string} domain Cookie Domain
 */
Formalis.Cookie.DeleteCookie = function (name, path, domain) {
    try {
        if (GetCookie(name)) {
            document.cookie = name + "=" +
            ((path) ? "; path=" + path : "") +
            ((domain) ? "; domain=" + domain : "") +
            "; expires=Thu, 01-Jan-70 00:00:01 GMT";
        }
    } catch (e) {
        Formalis.Cookie.ErrorHandler(e);
    }
}


/**
 * @description Sets Cookie
 * @param {string} name Cookie Name
 * @param {string} value Cookie Value
 * @param {string} expires Cookie Expiration time
 * @param {string} path Cookie Path
 * @param {string} domain Cookie Domain
 * @param {string} secure Cookie Secure 
 */
Formalis.Cookie.SetCookie = function (name, value, expires, path, domain, secure) {
    try {
        var exdate = new Date();
        exdate.setDate(exdate.getDate() + expires);

        document.cookie = name + "=" + escape(value) +
        ((expires) ? "; expires=" + exdate.toUTCString() : "") +
        ((path) ? "; path=" + path : "") +
        ((domain) ? "; domain=" + domain : "") +
        ((secure) ? "; secure" : "");
    } catch (e) {
        Formalis.Cookie.ErrorHandler(e);
    }
};

/**
 * @description Default Error Handler function
 */
Formalis.Cookie.ErrorHandler = function (ex) {
    //TODO - MAK - Implement Error handler
    throw ex;
};
