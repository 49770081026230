Formalis.InstantMessaging.MessageWindow.prototype.handleSendNewMessage = function(obj){
    var value = obj.val();
    if(value != "" && value != null && value != undefined){
        this.sendNewMessage(value);
        obj.val("");
        Formalis.Utils.keepScrollDown(this.Controls.ScrollBarHolder());
    }
};


