/**
* @fileOverview  Instant Messaging Base Function 
* @name Javascript Base File - Namespace declaration
* @author Formalis\Mehmet Ali Koeken    
* @version 1.0
*/

Formalis.InstantMessaging.SetAuthKey = function (key) {
    Formalis.InstantMessaging._akey = key;
};
Formalis.InstantMessaging.SetAuthValue = function (value) {
    Formalis.InstantMessaging._avalue = value;
};
Formalis.InstantMessaging.GetAuthValue = function (value) {
    return Formalis.InstantMessaging._avalue;
};

Formalis.InstantMessaging.SetAuthHeader = function (xhr) {

    xhr.setRequestHeader("X-Formalis-Authz", "HABasic  "
        + Base64.encodeURLSafe(Formalis.InstantMessaging._akey + ":" + Formalis.InstantMessaging._avalue));
};
//Used for jsonp
//returns authorization query string parameter.
Formalis.InstantMessaging.SetAuthUrl = function () {
    return "?auth=" + Formalis.InstantMessaging._avalue;
};

Formalis.InstantMessaging.HandleDAOResponse = function (data) {
    //$.log('in: HandleDAOResponse');
    if (data != null
        && data.NewAuthorizationKey != undefined
        && data.NewAuthorizationKey != null
        && data.NewAuthorizationKey != "") {
        //$.log("about to renew authorization key to: " + data.NewAuthorizationKey);
        Formalis.InstantMessaging.SetAuthValue(data.NewAuthorizationKey);
    }
};

