Formalis.InstantMessaging.MessageWindow.prototype.markMessagesAcked = function (acks) {

    if (acks == null || acks == undefined || acks.length == 0)
        return;

    var obj = this;
    try {
        var mainHolder = this.Controls.MessagesHolder();


        for (var i = 0; i < acks.length; i++) {

            var ack = acks[i];

            var imgServerAck = obj.$('#imgServerAck_' + ack.MessageSeqNo, mainHolder);
            imgServerAck.removeClass();
            if (ack.AckServer) {
                imgServerAck.addClass(obj.Fields.ImgServerAckSrc);
            } else {
                imgServerAck.addClass(obj.Fields.ImgServerUnackSrc);
            }

            var imgRemoteCliAck = obj.$('#imgRemoteCliAck_' + ack.MessageSeqNo, mainHolder);
            imgRemoteCliAck.removeClass();
            if (ack.AckRemoteClient) {
                imgRemoteCliAck.addClass(obj.Fields.ImgRemoteCliAckSrc);
            } else {
                imgRemoteCliAck.addClass(obj.Fields.ImgRemoteCliUnackSrc);
            }
        }

        Formalis.Utils.keepScrollDown(this.Controls.ScrollBarHolder());
    } catch (err) {
        obj.HandleBusinessError(err);
    }
};

Formalis.InstantMessaging.MessageWindow.prototype.appendCustomerCustomFieldInfo = function (customFieldData) {

    if (customFieldData == null)
        return;

    var obj = this;
    try {

        var mainHolder = this.Controls.CustomerCustomFieldInfoHolder();
        var secondTable = $('#customFieldsInformation', mainHolder);
        var exist = false; 

        if (secondTable.length > 0) {
            secondTable.html("");
            exist = true;
        }
        else {
            secondTable = $('<table id="customFieldsInformation"/>');
        }

        for (idx in customFieldData) {
            var item = customFieldData[idx];
            var tableRow = $('<tr/>');
            var tableKeyCell = $('<td/>');
            var tableValueCell = $('<td/>');

            tableKeyCell.html(item.Key);
            tableValueCell.html(item.Value);
            tableRow.append(tableKeyCell).append(tableValueCell);
            secondTable.append(tableRow);
        }
        if (!exist)
            mainHolder.append(secondTable);
    } catch (err) {
        obj.HandleBusinessError(err);
    }
};

/**
 *
 * @param {object} data inputDescription
 * @requires jtemplates jquery plugin
 */
Formalis.InstantMessaging.MessageWindow.prototype.appendNewMessages = function (data, messageSenderType) {
    var obj = this;
    var $ = Formalis.ClassBase.prototype.$;

    try {
        var selfMessage = messageSenderType == Formalis.InstantMessaging.Enum.MessageSenderType.User ? 1 : 0;
        // remove messages that are already received.
        if (data != null && data != undefined) {
            var newMessages = $.grep(data.Messages, function (n, i) {

                var exists = false;
                $.each(obj._messageBag, function (index, value) {
                    if (
                    (typeof (n.MessageSeqNo) == 'undefined'
                        && n.MessageText == value.MessageText)
                        || (n.MessageSeqNo != null
                        && n.MessageSeqNo == value.MessageSeqNo
                        && n.Sender.Username == value.Sender.Username)) {
                            exists = true;
                            return false;
                        }

                    return true;
                });

                return !exists;
            });
            $.each(newMessages, function (index, value) {
                var tempValue = value.MessageText;
                if (selfMessage)
                    tempValue = jQuery('<div />').text(value.MessageText).html();
                if (messageSenderType == Formalis.InstantMessaging.Enum.MessageSenderType.Participants) {
                    tempValue = Formalis.Utils.linkify(tempValue);
                }
                value.MessageText = tempValue;
                value.getMessageUserTypeClass = function () {

                    var cssClass = "l-side ";
                    if (selfMessage)
                        cssClass = "r-side ";

                    if ((value.MessageType && value.MessageType.Value == 2) || value.SenderSessionUserType == undefined) {
                        cssClass = "system ";
                    }
                    switch (value.SenderSessionUserType) {
                        case 0:
                            cssClass = "system";
                            break;
                        case 1:
                            cssClass += "customer ";
                            break;
                        case 2:
                            cssClass += "operator ";
                            break;
                        case 4:
                            cssClass += "auditor ";
                            break;
                        default:
                            break;
                    }

                    return cssClass;
                }
                newMessages[index] = value;
                obj._messageBag.push(value);
            });
            data.Messages = newMessages;
            this.InvokeWindowsApplication_GotNewMessages(newMessages);
        }

        var mainHolder = this.Controls.MessagesHolder();
        mainHolder.setTemplate(this.HTMLTemplates.AppendNewMessage, [], { filter_data: false });

        mainHolder.setParam('SelfMessage', selfMessage);
        mainHolder.setParam('ServerUnackImageSrc', obj.Fields.ImgServerUnackSrc);
        mainHolder.setParam('RemoteClientUnackImageSrc', obj.Fields.ImgRemoteCliUnackSrc);

        var old = mainHolder.html();
        mainHolder.processTemplate(data);

        mainHolder.prepend(old);
        Formalis.Utils.keepScrollDown(this.Controls.ScrollBarHolder());
    } catch (err) {
        obj.HandleBusinessError(err);
    }
};

Formalis.InstantMessaging.MessageWindow.prototype.addOperatorWaitingText = function () {

    var mainHolder = this.Controls.MessagesHolder();
    mainHolder.setTemplate(this.HTMLTemplates.Invitations_OperatorWaiting);
    mainHolder.setParam('OperatorWaitingClass', this.Fields.OperatorWaitingClass);
    mainHolder.setParam('AjaxLoaderImageSrc', this.Fields.AjaxLoaderImageSrc);
    mainHolder.setParam('AjaxLoaderImageClass', this.Fields.AjaxLoaderImageClass);
    mainHolder.setParam('OperatorWaitingClass', this.Fields.OperatorWaitingClass);
    mainHolder.setParam('OperatorWaitingText', this.Resource.Lang.WaitingForOperator);
    mainHolder.setParam('OperatorWaitingClassHolder', "");
    mainHolder.processTemplate();
    Formalis.Utils.keepScrollDown(this.Controls.ScrollBarHolder());
};


Formalis.InstantMessaging.MessageWindow.prototype.sendEmailText = function () {
    var mainHolder = this.Controls.MessagesHolder();
    mainHolder.setTemplate(this.HTMLTemplates.OperatorInformation);
    mainHolder.setParam('SessionWithOperatorMessageClass', this.Fields.InformationMessageClass);
    mainHolder.setParam('Message', this.Resource.Lang.SendEmailMessage);
    var old = mainHolder.html();
    mainHolder.processTemplate();
    mainHolder.prepend(old);
    Formalis.Utils.keepScrollDown(this.Controls.ScrollBarHolder());
};

Formalis.InstantMessaging.MessageWindow.prototype.sendEmailErrorText = function() {
    var mainHolder = this.Controls.MessagesHolder();
    mainHolder.setTemplate(this.HTMLTemplates.OperatorInformation);
    mainHolder.setParam('SessionWithOperatorMessageClass', this.Fields.InformationMessageClass);
    mainHolder.setParam('Message', this.Resource.Lang.SendEmailError);
    var old = mainHolder.html();
    mainHolder.processTemplate();
    mainHolder.prepend(old);
    Formalis.Utils.keepScrollDown(this.Controls.ScrollBarHolder());
}