/**
 * Online Clients fields
 * 
 */
Formalis.InstantMessaging.OnlineUsers.prototype.Fields = function () {
    
    var that = {
        DOMElement_prefix : 'formalis_',
        userMessageRequestClass: 'operatorname',
        DraggableHandle: 'HA_Users_Popup_Window_Header'
    }
    return that;
}() ;

