Formalis.InstantMessaging.OnlineUsers.prototype.HTMLTemplates = {
    CreateOnlineUsersTable:
         '{#foreach $T.Users as user}' +
            '<div class="live-help-requests clearfix">' +
                '<a href="#" class="{$P.userMessageRequestClass}" uid="{$T.user.sUserID}" bcid="{$T.BCID}" windowKey="{$T.user.Name}{$T.user.Surname}">{$T.user.Name} {$T.user.Surname}</a>' +
            '</div>' +
        '{#/for}',
    OnlineUsers_MainStructure:
            '',
    OnlineUsers_NoUsersOnline:
        '<div id="{$P.Prefix}OnlineUsers_NoUsersOnline2">{$P.NoUsersOnlineText}</div>'
};