/**
* Handler function for getNewInvitationsDAO method.
* This function checks if there are new invitations.
*  If there are new Invitations, produces new Invitations list table
*  else outputs error message indicating that there are no new invitations.
* @param {object} data WebService ajax call result
* @returns {void} void
*/
Formalis.InstantMessaging.MessageWindow.prototype.Handler_GetNewMessages = function (data) {
    try {
        if (data.Messages != null && data.Messages != undefined && data.Messages.length > 0) {
            if (this.CheckForSessionEndMessageAndAdjustTime(data) === true) {
                this.stopAllProcesses();
            }
            this.appendNewMessages(data, Formalis.InstantMessaging.Enum.MessageSenderType.Participants);
        }
    } catch (e) {
        this.Handle_GetNewMessages_Error(e);
    }
};

Formalis.InstantMessaging.MessageWindow.prototype.InvokeWindowsApplication_GotNewMessages = function (messages) {

    if (window.external != null
        && window.external != undefined
        && typeof (window.external.GotNewMessages) != 'undefined') {

        try {
            var firstMessage = '';

            if (messages != null
                && messages != undefined) {
                firstMessage = messages[0].MessageText;
            }

            window.external.GotNewMessages(this._invitationTopic, firstMessage);
        } catch (ex) {

        }

    }
};

Formalis.InstantMessaging.MessageWindow.prototype.Handler_SyncMessages = function (data) {
    try {
        if (data.Messages != null && data.Messages != undefined && data.Messages.length > 0) {
            if (this.CheckForSessionEndMessageAndAdjustTime(data) === true) {
                this.stopAllProcesses();
            }
            this.appendNewMessages(data, Formalis.InstantMessaging.Enum.MessageSenderType.Participants);
        }
        if (data.IsWriting != null && data.IsWriting != undefined && data.IsWriting.length > 0) {
            if (data.IsWriting == "1") {
                this.Controls.WritingMessageHolder().text(this.Resource.Lang.WritingMessage);
                this.Controls.ChatScroll().mCustomScrollbar("scrollTo", "bottom", { scrollInertia: 500, scrollEasing: "easeInOutSmooth" });
            }
            else {
                this.Controls.WritingMessageHolder().text('');
            }
        }
        else {
            this.Controls.WritingMessageHolder().text('');
        }
        if (data.Acks != null && data.Acks != undefined && data.Acks.length > 0) {
            this.markMessagesAcked(data.Acks);
        }
        if (data.Acks != null && data.Acks != undefined && data.Acks.length > 0) {
            this.markMessagesSent(data.Acks);
        }
    } catch (e) {

        $.log('Sync Message Exception : ' + e);

        this.Handle_SyncMessages_Error(e);
    }
};

Formalis.InstantMessaging.MessageWindow.prototype.CheckForSessionEndMessageAndAdjustTime = function (data) {
    var retVal = false;
    try {
        var serverTime = null;
        var newTime = null;
        var hour = 0;
        var minute = 0;
        var second = 0;
        for (i = 0; i < data.Messages.length; i++) {


            newTime = "";
            serverTime = data.Messages[i].MessageTime.split(':');
            hour = parseInt(serverTime[0]) - Formalis.InstantMessaging.TimeCalibrationValues.Hour;
            minute = parseInt(serverTime[1]) - Formalis.InstantMessaging.TimeCalibrationValues.Minute;
            second = parseInt(serverTime[2]) - Formalis.InstantMessaging.TimeCalibrationValues.Second;

            if (second < 0) {
                minute--;
                second += 60;
            }

            if (minute < 0) {
                hour--;
                minute += 60;
            }

            if (second > 59) {
                minute++;
                second -= 60;
            }

            if (minute > 59) {
                hour++;
                minute -= 60;
            }

            if (hour < 10) {
                hour = "0" + hour;
            }
            newTime += hour;

            if (minute < 10) {
                minute = "0" + minute;
            }
            newTime += ":" + minute;

            if (second < 10) {
                second = "0" + second;
            }
            newTime += ":" + second;

            data.Messages[i].MessageTime = newTime;

            //7-> Session End
            if (data.Messages[i].MessageType.Value == 7) {
                retVal = true;
            }
        }
    } catch (e) {
        //alert(e);
        this.Handle_GetNewMessages_Error(e);
    }
    return retVal;
};


/**
* Handler function for getNewInvitationsDAO method.
* This function checks if there are new invitations.
*  If there are new Invitations, produces new Invitations list table
*  else outputs error message indicating that there are no new invitations.
* @param {object} data WebService ajax call result
* @returns {void} void
*/
Formalis.InstantMessaging.MessageWindow.prototype.Handler_EndSession = function (data) {
    try {
        this.deactiveControls();
    } catch (e) {
        this.Handle_GetNewMessages_Error(e);
    }
};
/**
* Handler function for getNewInvitationsDAO method.
* This function checks if there are new invitations.
*  If there are new Invitations, produces new Invitations list table
*  else outputs error message indicating that there are no new invitations.
* @param {object} data WebService ajax call result
* @returns {void} void
*/
Formalis.InstantMessaging.MessageWindow.prototype.Handler_ConvertToIssue = function (data) {
    var obj = this;
    try {
        //window.open(obj.Fields.TargetApplicationConvertToIssueURL + obj._sessionID);
        window.open(obj.Fields.TargetApplicationConvertToIssueURL);
    } catch (e) {
        this.Handle_GetNewMessages_Error(e);
    }
};
