/**
 * Records current time information(hours, minutes and seconds.).
 */
Formalis.ActivityMonitor.prototype.RecordActivity = function () {
    var oDate = new Date();
    this.lastActivity["oHid_h"] = oDate.getHours();
    this.lastActivity["oHid_mn"] = oDate.getMinutes();
    this.lastActivity["oHid_s"] = oDate.getSeconds();
};

/**
 * Activity monitor tick handler. If last activity time exceeds the maximum time without activity, then inactivity event is fired, otherwise active event is fired.
 */
Formalis.ActivityMonitor.prototype.VerifyActivity = function (EventArgs,scope) {
    //$.log("verify activity tick");
    var oDate = new Date();
    var oLastActDate = new Date(oDate.getFullYear(), oDate.getMonth(), oDate.getDate(), scope.lastActivity["oHid_h"], scope.lastActivity["oHid_mn"], scope.lastActivity["oHid_s"]); // last activity date and time.
    //$.log(oDate.getTime() - oLastActDate.getTime());
    if ((oDate.getTime() - oLastActDate.getTime()) > scope.MAX_WITHOUT_ACTIVITY) {
        scope.Event.fire(scope.ActivityStop_EventName);
    } else {
        scope.Event.fire(scope.ActivityStart_EventName);
    }
};

/**
 * Binds Activity Monitor on the page. Binds to DOM Body.
 * Starts ActiveMonitor Cron Job.
 */
Formalis.ActivityMonitor.prototype.BindActivityMonitor = function () {
    var parent = this;
    this.RecordActivity();
    this.$('body').bind('mousemove', function () { parent.RecordActivity(); }).bind('keydown', function () { parent.RecordActivity(); });
    this.ChroneJob.addTickHandler(this.VerifyActivity, this);
};

/**
 * Starts Monitoring for activity
 */
Formalis.ActivityMonitor.prototype.StartMonitoring = function () {
    //Bind Events
    try {
        this.ChroneJob.start();
    } catch (ex) {
        $.log(ex.description);
    }
};

/**
 * Stops monitoring for activity.
 * Clears all the events
 */
Formalis.ActivityMonitor.prototype.StopMonitoring = function () {
    this.ChroneJob.stop();
    this.Event = null;
};

/** 
 * Pauses monitoring. Keeps events.
 */
Formalis.ActivityMonitor.prototype.PauseMonitoring = function () {
    this.ChroneJob.stop();
};

/**
 * Resumes monitoring
 */
Formalis.ActivityMonitor.prototype.ResumeMonitoring = function () {
    this.ChroneJob.start();
};
