/**
* Event Handler for UpdateUserStatus Chrone Job Tick event.
*/
Formalis.InstantMessaging.MessageWindow.prototype.GetNewMessages_TickHandler = function (eventArgs, scope) {
    scope.getNewMessages();
    scope.CheckAndHandleConnectionInstability();
};
/**
* Binds event handlers for UpdateUserStatus events.
*/
Formalis.InstantMessaging.MessageWindow.prototype.bindChroneJobEventHandlers_GetNewMessages = function () {
    this._getNewMessages_ChroneJob.addTickHandler(this.GetNewMessages_TickHandler, this);
    this._getNewMessages_ChroneJob.start();
};

/**
* Event Handler for UpdateUserStatus Chrone Job Tick event.
*/
Formalis.InstantMessaging.MessageWindow.prototype.syncMessages_TickHandler = function (eventArgs, scope) {
    scope.syncMessages();
    scope.CheckAndHandleConnectionInstability();
};
/**
* Event Handler for UpdateUserStatus Chrone Job Tick event.
*/
Formalis.InstantMessaging.MessageWindow.prototype.customFieldList_TickHandler = function (eventArgs, scope) {
    scope.refreshCustomFieldList();
    if (scope.canDismissCustomFieldListChroneJob()) {
        scope._customFieldList_ChroneJob.stop();
    }
};
/**
* Binds event handlers for UpdateUserStatus events.
*/
Formalis.InstantMessaging.MessageWindow.prototype.bindChroneJobEventHandlers_SyncMessages = function () {
    this._syncMessages_ChroneJob.addTickHandler(this.syncMessages_TickHandler, this);
    this._syncMessages_ChroneJob.start();
};
/**
* Binds event handlers for CustomFieldList update.
*/
Formalis.InstantMessaging.MessageWindow.prototype.bindChroneJobEventHandlers_RefreshCustomFieldList = function () {
    this._customFieldList_ChroneJob.addTickHandler(this.customFieldList_TickHandler, this, 20);
    this._customFieldList_ChroneJob.start();
};
