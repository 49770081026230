Formalis.InstantMessaging.MessageWindow.prototype.bindSendNewMessageEvent = function () {
    /** @property {object} $ Javascript Framework (default JS Framework is JQuery */
    var obj = this;
    this.Controls.txtMessageBox().off('keypress.sessionActive');
    this.Controls.txtMessageBox().on('keypress.sessionActive', function (e) {
        var code = (e.keyCode ? e.keyCode : e.which);
        if (code == 13) {
            e.preventDefault();
            obj.handleSendNewMessage($(this));
            $(this).val('');
            var nowTime = new Date();
            obj.LastTypingTime = nowTime.setMinutes(nowTime.getMinutes() - 1); // finalize ongoing  typing message when enter pressed
        }
        else {
            obj.LastTypingTime = new Date();
        }
    });
    this.Controls.SendMessageButton().off('click.sessionActive');
    this.Controls.SendMessageButton().on('click.sessionActive', function (e) {
        e.preventDefault();
        obj.handleSendNewMessage(obj.Controls.txtMessageBox());
        obj.Controls.txtMessageBox().trigger('keyup');
    });
    this.Controls.EndSessionButton().off('click.sessionActive');
    this.Controls.EndSessionButton().on('click.sessionActive', function (e) {
        e.preventDefault();
        obj.endSession();
    });
    this.Controls.GoOfflineButton().off('click.sessionActive');
    this.Controls.GoOfflineButton().on('click.sessionActive', function(e) {
        e.preventDefault();
        obj.goOffline();
    });
    this.Controls.ConvertToIssueButton().off('click.sessionActive');
    this.Controls.ConvertToIssueButton().on('click.sessionActive', function (e) {
        e.preventDefault();
        //Send request to web service so that it creates session history file in the target applications listener folder.
        obj.convertToIssueDAO();
        //open new window with target applications new record screen.
    });
    this.Controls.SendEmailButton().off('click.sessionActive');
    this.Controls.SendEmailButton().on('click.sessionActive', function (e) {
        e.preventDefault();
        obj.sendEmailDAO();
    });
};

Formalis.InstantMessaging.MessageWindow.prototype.bindDeactivateControls = function () {
    /** @property {object} $ Javascript Framework (default JS Framework is JQuery */
    var obj = this;
    this.Controls.txtMessageBox().off('keypress.sessionActive');
    this.Controls.txtMessageBox().on('keypress.deactivateControls', function (e) {
        e.preventDefault();
    });
    this.Controls.txtMessageBox().attr('disabled', 'disabled');
    this.Controls.sendMessageIcons().attr('disabled', 'disabled');

    this.Controls.SendMessageButton().off('click.sessionActive');
    this.Controls.SendMessageButton().on('click.deactivateControls', function (e) {
        e.preventDefault();
    });

    this.Controls.EndSessionButton().off('click.sessionActive');
    this.Controls.EndSessionButton().on('click.deactivateControls', function (e) {
        e.preventDefault();
    });

    this.Controls.SelectTemplateButton().off('click.sessionActive');
    this.Controls.SelectTemplateButton().on('click.deactivateControls', function (e) {
        e.preventDefault();
    });
    this.Controls.GoOfflineButton().off('click.sessionActive');
    this.Controls.GoOfflineButton().on('click.deactivateControls', function(e) {
        e.preventDefault();
    });

    this.Controls.EndSessionButton().attr("disabled", "disabled");
    this.Controls.SendMessageButton().attr("disabled", "disabled");
    this.Controls.SelectTemplateButton().attr("disabled", "disabled");
    this.Controls.SendEmailButton().attr("disabled", "disabled");
    this.Controls.GoOfflineButton().attr("disabled", "disabled");
};

Formalis.InstantMessaging.MessageWindow.prototype.removeDeactivateControlsEventHandlers = function () {
    /** @property {object} $ Javascript Framework (default JS Framework is JQuery */
    var obj = this;
    this.Controls.txtMessageBox().off('keypress.deactivateControls');
    this.Controls.txtMessageBox().removeAttr('disabled');
    this.Controls.txtMessageBox().attr("placeholder", "Mesajınızı buraya yazınız");

    this.Controls.SendMessageButton().off('click.deactivateControls');
    this.Controls.EndSessionButton().off('click.deactivateControls');
    this.Controls.GoOfflineButton().off('click.deactivateControls');
    this.Controls.EndSessionButton().removeAttr("disabled");
    this.Controls.SendMessageButton().removeAttr("disabled");
    this.Controls.sendMessageIcons().removeAttr('disabled');
    this.Controls.SelectTemplateButton().removeAttr("disabled");
    this.Controls.ConvertToIssueButton().removeAttr("disabled");
    this.Controls.SendEmailButton().removeAttr("disabled");
    this.Controls.GoOfflineButton().removeAttr("disabled");

};

Formalis.InstantMessaging.MessageWindow.prototype.bindOnunloadEvent = function () {
    var obj = this;
    var $ = Formalis.ClassBase.prototype.$;

    $(window).on('beforeunload', function () {
        if (!obj._isAuditor && !obj.sessionEnded) {
            return obj.Resource.Lang.AreYouSureToEndSession;
        }
    });

    $(window).unload(function () {
        if (!obj._isAuditor && !obj.sessionEnded) {
            // oturum devam etmiyorsa sonlandırılacak!
            obj.endSession(true);
        }
    });

};

