/**
 * @fileOverview Online Users class definition
 * @author Mehmet Ali Koeken
 * @version 1.0
 * @name Online Users
 * @requires Formalis.Base, Formalis.InstantMessaging
 */

/**
 * Online User List class manages operations related to Online Users 
 * such as keeping a user online or retrieving online user list
 * @class 
 */
Formalis.InstantMessaging.OnlineUsers = function(){
    this._webServiceName = 'WebService/IM_OnlineUsers_Public.svc/';
    this._webServiceServer = Formalis.InstantMessaging.InternalWebServiceServerURL;
    this.proxy =  new Formalis.ServiceProxy(this._webServiceName,this._webServiceServer);
    /** Holds all the errors UpdateUserStatus */
    this._error_updateUserStatus = new Array();
    /** Threshold for number of UpdateUserStatus errors before stopping process */
    this.threshold_error_updateUserStatus = 5;
    /** Holds all the errors UpdateUserStatus */
    this._error_getOnlineUsers = new Array();
    /** Threshold for number of UpdateUserStatus errors before stopping process */
    this.threshold_error_getOnlineUsers = 5;
    
    /** Default Update User Status Interval is 2000 seconds */
    this.GetOnlineUsersInterval = 15000;
    this._getOnlineUsers_ChroneJob = new Formalis.ChroneJob("GetOnlineUsers", this.GetOnlineUsersInterval);
    
    this._onlineUsersList = {};
    this._authorizedToGetOnlineList = false;
};

Formalis.InstantMessaging.OnlineUsers.inheritsFrom(Formalis.ClassBase);