/**
 * Event Handler for UpdateUserStatus Chrone Job Tick event.
 */
Formalis.InstantMessaging.Invitation.prototype.getNewInvitations_TickHandler = function (eventArgs, scope) {
    scope.getNewInvitations();
    scope.CheckAndHandleConnectionInstability();
};
/**
 * Binds event handlers for UpdateUserStatus events.
 */
Formalis.InstantMessaging.Invitation.prototype.bindChroneJobEventHandlers_GetNewInvitations = function () {
    this.getNewInvitations_TickHandler(null, this);
    this._getNewInvitations_ChroneJob.addTickHandler(this.getNewInvitations_TickHandler, this);
    this._getNewInvitations_ChroneJob.start();
};

/**
 * Binds event handlers for UpdateUserStatus events.
 */
Formalis.InstantMessaging.Invitation.prototype.unbindChroneJobEventHandlers_GetNewInvitations = function () {
    this._getNewInvitations_ChroneJob.removeTickHandler(this.GetNewInvitations_TickHandler, this);
    this._getNewInvitations_ChroneJob.stop();
};
/**
 * Event Handler for UpdateUserStatus Chrone Job Tick event.
 */
Formalis.InstantMessaging.Invitation.prototype.checkInvitationStatus_TickHandler = function (eventArgs, scope) {
    scope.checkInvitationStatus();
    scope.CheckAndHandleConnectionInstability();
};
/**
 * Binds event handlers for UpdateUserStatus events.
 */
Formalis.InstantMessaging.Invitation.prototype.bindChroneJobEventHandlers_CheckInvitationStatus = function () {
    this._checkInvitationStatus_ChroneJob.addTickHandler(this.checkInvitationStatus_TickHandler, this);
    this._checkInvitationStatus_ChroneJob.start();
};

/**
 * Binds event handlers for UpdateUserStatus events.
 */
Formalis.InstantMessaging.Invitation.prototype.unbindChroneJobEventHandlers_CheckInvitationStatus = function () {
    this._checkInvitationStatus_ChroneJob.removeTickHandler(this.checkInvitationStatus_TickHandler, this);
    this._checkInvitationStatus_ChroneJob.stop();
};

