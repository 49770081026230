/**
 * Handler function for UpdateOnlineStatusDAO method. 
 * @param {object} data WebService ajax call result
 * @returns {void} void
 */
Formalis.InstantMessaging.OnlineUsers.prototype.Handler_UpdateOnlineStatus = function(data){
    //TODO - MAK - Develop if needed
    };
/**
 * Handler function for GetOnlineUsersDAO method.
 * This function checks if there are online users:
 *  If there are online users, produces online user list table
 *  else outputs no online users table.
 * @param {object} data WebService ajax call result
 * @returns {void} void
 */
Formalis.InstantMessaging.OnlineUsers.prototype.Handler_GetOnlineUsers = function (data) {
    var obj = this;
    try {
        if (data == null || data.Error) {
            throw new Formalis.Exception("Get online data is null");
        }
        if (data.Users.length > 0) {
            this.GenerateOnlineListTable(data);
            this.bindStartSessionClickEvent();

        } else {
            this.ShowNoUsersOnlineWindow();
        }
        //this.bindDraggableEvent();
    //            $.pnotify({
    //                pnotify_title: 'Online User List Recieved',
    //                pnotify_text: 'Yes IT Did',
    //                pnotify_delay: 1000,
    //                pnotify_history: false
    //            });
    } catch (err) {

        obj.Handle_UpdateOnlineStatus_Error(err);
    }

};
/**
 * Handler function for GetOnlineUsersDAO method.
 * This function checks if there are online users:
 *  If there are online users, produces online user list table
 *  else outputs no online users table.
 * @param {object} data WebService ajax call result
 * @returns {void} void
 */
Formalis.InstantMessaging.OnlineUsers.prototype.Handler_CheckAuthorization = function(data){
    var obj = this;
    try{
        if(data == null || data.Error){
            throw new Formalis.Exception("Get online data is null");
        }
        if(data.StopProcess){
             this.stopAllProcesses();
        }else{
            this._authorizedToGetOnlineList = true;
            this.startProcess();
        }
    }catch(err){
        obj.Handle_UpdateOnlineStatus_Error(err);
    }
    
};
