Formalis.ServiceLog.prototype.Validate_ServiceLogObj = function(serviceLogObj){
    var errorList = new Array();
    if(serviceLogObj == null || serviceLogObj == undefined){
        errorList.push(this.Resource.Lang.serviceLogObjEmpty);
    }else{
      
        if(serviceLogObj.LogMessage.IsEmpty()){
            errorList.push(this.Resource.Lang.serviceLogMessageEmpty);
        }
        if(serviceLogObj.LogType.IsEmpty()){
            errorList.push(this.Resource.Lang.serviceLogTypeEmpty);
        }
    }
    if(errorList.length<=0){
        return true;
    }else{
        var ex = new Formalis.Exception();
        ex.Type =  Formalis.Enum.ExceptionType.Validation;
        for (i = 0; i < errorList.length; i++) {
            ex.Message += errorList[i];
        }
        return ex;
    }
};