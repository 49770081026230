Formalis.ServiceLog.prototype.saveLog = function (serviceName,message) {
    try {
        this.saveLogDAO({
            "serviceLog":{
                Message:message,
                ServiceName:serviceName
            }
        });
    }
    catch (e) {
        this.Handle_SaveLog_Error(e);
    }
};

Formalis.ServiceLog.prototype.GetAllLogs = function(){
    var obj = this;
    try {
        this.GetAllLogsDAO();
    } catch (e) { 
        obj.ErrorHandler(e);
    }
};


