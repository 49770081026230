/** Binds all the events, chrone jobs, initiates Invitation related operations
*
*/
Formalis.InstantMessaging.Invitation.prototype.init = function (checkSendInvitationAuthorization) {
    //this.bindMainDOMStructure();
    //    this.bindStartSessionClickEvent();
    //    this.bindChroneJobEventHandlers_GetNewInvitations();    
    this.checkAuthorizationDAO();
    if (checkSendInvitationAuthorization == null
        || checkSendInvitationAuthorization == undefined
        || checkSendInvitationAuthorization) {
        this.checkSendInvitationAuthorizationDAO();
    }

};

/**
*  Gets new invitations that belongs to the current user.
* @param {inputType} input paramDescription
* @returns {void} void
*/
Formalis.InstantMessaging.Invitation.prototype.getNewInvitations = function () {
    try {
        this.getNewInvitationsDAO();
    } catch (err) {
        this.HandleBusinessError(err);
    }
};

/**
* Stops all processes for Online Users class.
* This includes all the chrone jobs, data connections to web services, internal evaluations.
*/
Formalis.InstantMessaging.Invitation.prototype.stopAllProcesses = function () {
    this.unbindChroneJobEventHandlers_CheckInvitationStatus();
    this.unbindChroneJobEventHandlers_GetNewInvitations();
    this.logError();
};

/**
* Binds main dom structure that will facilitate all the dynamically generated DOM Elements.
*/
Formalis.InstantMessaging.Invitation.prototype.bindMainDOMStructure = function () {
    this.GenerateMainStructure();
};

Formalis.InstantMessaging.Invitation.prototype.checkInvitationStatus = function () {
    try {
        this.checkInvitationStatusDAO();
    } catch (err) {
        this.HandleBusinessError(err);
    }
};
/**
* Binds the check invitation status logic (chronejob)
*/
Formalis.InstantMessaging.Invitation.prototype.bindOperatorWaiting = function () {
    try {
        var messageWindow = new Formalis.InstantMessaging.MessageWindow();
        messageWindow.deactiveControls();
        this.checkInvitationStatus();
        this.bindChroneJobEventHandlers_CheckInvitationStatus();
    } catch (err) {
        this.HandleBusinessError(err);
    }
};

Formalis.InstantMessaging.Invitation.prototype.hideInvitationsPanel = function () {
    try {
        this.MainWrapper().hide();
        this.MainTableHolder().html('');
    } catch (err) {
        this.HandleBusinessError(err);
    }
};

Formalis.InstantMessaging.Invitation.prototype.startProcess = function () {
    try {
        this.bindMainDOMStructure();
        this.bindChroneJobEventHandlers_GetNewInvitations();
        this.bindStartSessionClickEvent();
        this.getNewInvitations();
    } catch (err) {
        this.HandleBusinessError(err);
    }
};


Formalis.InstantMessaging.Invitation.prototype.getNewInvitationsForNotification = function (newOnlineList) {
    var newInvitations = new Array();
    var doesUserExists = false;
    for (var i in newOnlineList) {
        doesUserExists = false;
        for (var j in this._invitationList) {
            if (this._invitationList[j].Key == newOnlineList[i].Key) {
                doesUserExists = true;
            }
        }
        if (!doesUserExists) {
            newInvitations.push(newOnlineList[i]);
        }
    }
    return newInvitations;
};


Formalis.InstantMessaging.Invitation.prototype.GetNewInvitationList_ActivityMonitor_StartHandler = function (event, scope) {
    if (scope._authorizedToGetInvitationList
        && scope._getNewInvitations_ChroneJob == null) {
        scope._getNewInvitations_ChroneJob = new Formalis.ChroneJob("Get_New_Invitations", scope.GetNewInvitationsInterval);
        scope.bindChroneJobEventHandlers_GetNewInvitations();
    }
};

Formalis.InstantMessaging.Invitation.prototype.GetNewInvitationList_ActivityMonitor_StopHandler = function (event, scope) {
    if (scope._authorizedToGetInvitationList
        && scope._getNewInvitations_ChroneJob != null) {
        scope._getNewInvitations_ChroneJob.stop();
        scope._getNewInvitations_ChroneJob = null;
    }
};

Formalis.InstantMessaging.Invitation.prototype.cancelPendingInvitation = function (skipConfirm) {

    var obj = this;
    if (skipConfirm == undefined || skipConfirm == null) {
        skipConfirm = false;
    }
    try {
        var confirmResult = true;
        if (!skipConfirm) {
            confirmResult = confirm(obj.Resource.Lang.AreYouSureToCancelInvitation);
        }
        if (obj.isInvitationPending && (confirmResult || skipConfirm)) {
            obj.cancelPendingInvitationDAO();

            obj.isInvitationPending = false;

            setTimeout('window.close()', obj.Fields.closeWindowAfterCancelInvitationTimeout);
        }
    } catch (err) {
        this.HandleBusinessError(err);
    }
};



