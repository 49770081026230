/**
 * @fileOverview Holds Formalis Enum Definition
 * @name Formalis.Enum
 * @author Formalis\Mehmet Ali Köken
 * @version 1.0
 */

/**
 * Enum Object Initiator.
 */
Formalis.InstantMessaging.Enum = {};
/**
 * Cache Priority. First use in Formalis.Cache class.

/**
 * Enum MessageType
 */
Formalis.InstantMessaging.Enum.MessageSenderType = {
    User : 1,
    Participants : 2
};

