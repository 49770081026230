
/**
* Online Clients fields
* 
*/
Formalis.InstantMessaging.Invitation.prototype.Fields = function () {

    var that = {
        DOMElement_prefix: 'formalis_',
        Invitation_StartSession_LinkClass: 'invitation_Invitation_startSession_link',
        ajaxLoaderImageSrc: '',
        DraggableHandle: 'HA_PopUp_Window_Header',
        closeWindowAfterCancelInvitationTimeout: 3000
    }
    return that;
} ();

