/**
 * @description Class Controls. Uses JS Framework (default JQuery) to get Page Control
 *
 */
Formalis.InstantMessaging.CustomFields.prototype.Controls = function () {
    /** @property {object} $ Javascript Framework (default JS Framework is JQuery */
    var $ = Formalis.ClassBase.prototype.$;
    var that = {
        DialogPreSessionCustomFields: function () {
            return $('#dialog-custom-fields');
        },
        DialogPreSessionCustomFieldsForm: function () {
            return $('#dialog-custom-fields form');
        },
        DialogPostSessionCustomFields: function () {
            return $('#dialog-custom-fields');
        },
        DialogPostSessionCustomFieldsForm: function () {
            return $('#dialog-custom-fields form');
        },
        DialogSessionCustomFieldsModal: function() {
            return $('#customFieldListModal');
        }
    }
    return that;
} ();

