/**
 * Handles UpdateOnlineStatusDao events.
 * If number of caught errors during update online status exceeds threshold,
 * all the OnlineUser class events are stopped resulting in an end in page activity for the class.
 * @param {object} errorObject Error Object
 * @returns {void} void
 */
Formalis.InstantMessaging.MessageWindow.prototype.Handle_GetNewMessages_Error = function(errorObject){
    this._error_getNewMessages.push(errorObject);
    this.CheckAndHandleConnectionInstability();
    $.log(errorObject);
};


/**
 * Handles UpdateOnlineStatusDao events.
 * If number of caught errors during update online status exceeds threshold,
 * all the OnlineUser class events are stopped resulting in an end in page activity for the class.
 * @param {object} errorObject Error Object
 * @returns {void} void
 */
Formalis.InstantMessaging.MessageWindow.prototype.Handle_SyncMessages_Error = function(errorObject){
    this._error_syncMessages.push(errorObject);
    this.CheckAndHandleConnectionInstability();
    $.log(errorObject);
};



/**
 * Handles UpdateOnlineStatusDao events.
 * If number of caught errors during update online status exceeds threshold,
 * all the OnlineUser class events are stopped resulting in an end in page activity for the class.
 * @param {object} errorObject Error Object
 * @returns {void} void
 */
Formalis.InstantMessaging.MessageWindow.prototype.Handle_SendNewMessage_Error = function (errorObject, messageText) {
    var messageTime = this.getMessageTime();
    var messageData = {
        Messages: new Array({
            MessageType: {
                Value: 1
            },
            MessageTime: messageTime,
            MessageText: this.Resource.Lang.ProblemSendingMessage.replaceAll("@MessageText", messageText),
            Sender: Formalis.InstantMessaging.CurrentUser
        })
    };
    this.appendNewMessages(messageData, Formalis.InstantMessaging.Enum.MessageSenderType.User);
    this._error_sendNewMessage.push(errorObject);
    this.CheckAndHandleConnectionInstability();
    $.log(errorObject);
};

Formalis.InstantMessaging.MessageWindow.prototype.CheckAndHandleConnectionInstability = function(){
    
    if(this._error_getNewMessages.length>this.threshold_error_getNewMessages){
        //end page activity.
        this.stopAllProcesses();
    //alert("all processes stopped.");
    //Display error text
    }
};


Formalis.InstantMessaging.MessageWindow.prototype.logError = function(){
    var errorMessage = "";
    errorMessage += " * Get New Messages Errors: " + Formalis.ErrorHandler.ErrorListToString(this._error_getNewMessages);
    $.log(Formalis.ErrorHandler.ErrorListToString(this._error_getNewMessages));
    var serviceLogObj = new Formalis.ServiceLog(Formalis.InstantMessaging.ServiceLogWebServiceBaseURL);
    serviceLogObj.saveLog("Client Side - Instant Messaging - MessageWindow Module",errorMessage);
};

Formalis.InstantMessaging.MessageWindow.prototype.HandleBusinessError = function(err){
    $.log(err);
};
Formalis.InstantMessaging.MessageWindow.prototype.Handle_EndSession_Error = function(err){
    $.log(err);
};
Formalis.InstantMessaging.MessageWindow.prototype.Handle_ConvertToIssue_Error = function (err) {
    $.log(err);
    alert(this.Resource.Lang.ProblemExportingSessionHistory);
};
