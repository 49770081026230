/**
 * Handles UpdateOnlineStatusDao events.
 * If number of caught errors during update online status exceeds threshold,
 * all the OnlineUser class events are stopped resulting in an end in page activity for the class.
 * @param {object} errorObject Error Object
 * @returns {void} void
 */
Formalis.InstantMessaging.OnlineUsers.prototype.Handle_UpdateOnlineStatus_Error = function(errorObject){
    this._error_updateUserStatus.push(errorObject);
    this.CheckAndHandleConnectionInstability();
    
};

Formalis.InstantMessaging.OnlineUsers.prototype.CheckAndHandleConnectionInstability = function(){
    
    if(this._error_getOnlineUsers.length>this.threshold_error_getOnlineUsers){
        //alert("_error_getOnlineUsers over limit");
        //end page activity.
        this.stopAllProcesses();
        //Display error text
    }
};


Formalis.InstantMessaging.OnlineUsers.prototype.logError = function(){
    var errorMessage = "";
    errorMessage += " * Get Online Users Errors: " + Formalis.ErrorHandler.ErrorListToString(this._error_getOnlineUsers);
    var serviceLogObj = new Formalis.ServiceLog(Formalis.InstantMessaging.ServiceLogWebServiceBaseURL);
    serviceLogObj.saveLog("Client Side - Instant Messaging - OnlineUsers Module",errorMessage);
};
Formalis.InstantMessaging.OnlineUsers.prototype.HandleBusinessError = function(businessMethod){
    
    //TODO - MAK - Handle Business Error
};
Formalis.InstantMessaging.OnlineUsers.prototype.Handle_GetOnlineUsers_Error = function(errorObject){
    this._error_getOnlineUsers.push(errorObject);
    this.CheckAndHandleConnectionInstability();
};
