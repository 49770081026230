/**
 * @fileOverview Holds Formalis Enum Definition
 * @name Formalis.Log
 * @author Formalis\Mehmet Ali Köken
 * @version 1.0
 */

/**
 * @class Log Class holds functionality for logging
 */
Formalis.Log = function () {

    };

/** 
 * Writes given log message
 * @param {string} input Log Message
 */
Formalis.Log.prototype.Write = function (input) {
    //TODO - MAK - implement functionality
    //    var obj = this;
    //    try {
    //
    //    } catch (e) {
    //        obj.ErrorHandler(e);
    //    }
    };

/**
 * @description Default Error Handler function
 */
Formalis.Log.prototype.ErrorHandler = function (ex) {
    throw ex;
};

