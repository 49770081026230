/** Binds all the events, chrone jobs, initiates Invitation related operations
*
*/
Formalis.InstantMessaging.MessageWindow.prototype.SeqNo = null;

Formalis.InstantMessaging.MessageWindow.prototype.init = function () {

    this.SeqNo = 0;
    Formalis.InstantMessaging.MessageWindow.IsInited = true; // bad baad, static variable.

    this.removeDeactivateControlsEventHandlers();
    this.bindSendNewMessageEvent();
    this.bindChroneJobEventHandlers_SyncMessages();
    this.bindChroneJobEventHandlers_RefreshCustomFieldList();
    this.syncMessages();
    this.bindOnunloadEvent();


    // fire static onInit event.
    Formalis.InstantMessaging.MessageWindow.StaticEvent.fire(
        this.Fields.ev_on_init,
        {
            session: this._sessionID
        });
};


/**
*  This method updates users online status
* @param {inputType} input paramDescription
* @returns {void} void
*/
Formalis.InstantMessaging.MessageWindow.prototype.getNewMessages = function () {
    try {
        this.getNewMessagesDAO();
    } catch (err) {
        this.HandleBusinessError(err);
    }
};


/**
*  This method updates users online status
* @param {inputType} input paramDescription
* @returns {void} void
*/
Formalis.InstantMessaging.MessageWindow.prototype.syncMessages = function () {

    var $ = Formalis.ClassBase.prototype.$;

    try {

        var unsentMessages = $.grep(this._messageRepository, function(n,i){
            return !n.IsSent;
        });

        this.syncMessagesDAO({
            messageList:  unsentMessages
        }, this._isAuditor);
    } catch (ex) {
        this.HandleBusinessError(ex);
    }
};

Formalis.InstantMessaging.MessageWindow.prototype.endSession = function (skipConfirm, skipMessage) {
    var obj = this;
    if (skipConfirm == undefined || skipConfirm == null) {
        skipConfirm = false;
    }
    try {
        var confirmResult = true;
        if (!skipConfirm) {
            confirmResult = confirm(this.Resource.Lang.AreYouSureToEndSession);
        }
        if (confirmResult || skipConfirm) {
            if (!skipMessage) {
                this.endSessionDAO();
                // Send session ended message.
                var messageTime = this.getMessageTime();
                //Information message.
                var messageData = {
                    Messages: new Array({
                        //Information message -> 2
                        MessageType: {
                            Value: 2
                        },
                        MessageTime: messageTime,
                        MessageText: this.Resource.Lang.YouHaveEndedTheSession,
                        Sender: Formalis.InstantMessaging.CurrentUser,
                        MessageSeqNo: 'mseq' + this.SeqNo
                    })
                };
                this.appendNewMessages(messageData, Formalis.InstantMessaging.Enum.MessageSenderType.User);
                this.SeqNo++;
                this.stopAllProcesses();
            }

            Formalis.InstantMessaging.MessageWindow.StaticEvent.fire(this.Fields.ev_session_end, {
                session: this._sessionID
            });

            if (this.Fields.CloseWindowAfterEndSession == 'True') {
                setTimeout('window.close()', this.Fields.CloseWindowAfterEndSessionTimeout);
            }

            this.Controls.txtMessageBox().attr('placeholder', 'Oturumunuz sonlanmıştır.');
            this.sessionEnded = true;
        }
    } catch (err) {
        this.HandleBusinessError(err);
    }


};
Formalis.InstantMessaging.MessageWindow.prototype.goOffline = function () {
    this.goOfflineDAO();
};
/**
*  This method updates users online status
* @param {inputType} input paramDescription
* @returns {void} void
*/
Formalis.InstantMessaging.MessageWindow.prototype.sendNewMessage = function (messageText) {
    try {
        //this.sendNewMessageDAO(messageText);
        var messageTime = this.getMessageTime();
        var message = {
            MessageType: {
                Value: 1
            },
            MessageTime: messageTime,
            MessageText: messageText,
            Sender: Formalis.InstantMessaging.CurrentUser,
            MessageSeqNo: 'mseq' + this.SeqNo,
            IsSent: false,
            SenderSessionUserType: Formalis.InstantMessaging.CurrentUser.SessionUserType
        };
        var messageData = {
            Messages: new Array(message)
        };
        this._messageRepository.push(message);
        this.appendNewMessages(messageData, Formalis.InstantMessaging.Enum.MessageSenderType.User);
        this.SeqNo++;
    } catch (err) {
        this.HandleBusinessError(err);
    }
};

/**
* Stops all processes for Online Users class.
* This includes all the chrone jobs, data connections to web services, internal evaluations.
*/
Formalis.InstantMessaging.MessageWindow.prototype.stopAllProcesses = function () {
    this.endSession(true, true);
    this._syncMessages_ChroneJob.stop();
    this._customFieldList_ChroneJob.stop();
    this.deactiveControls();
    this.logError();
};

/**
* Binds main dom structure that will facilitate all the dynamically generated DOM Elements.
*/
Formalis.InstantMessaging.MessageWindow.prototype.bindMainDOMStructure = function () {
    this.GenerateMainStructure();
};

Formalis.InstantMessaging.MessageWindow.prototype.bindClickEvent = function () {
    this.GenerateMainStructure();
};

Formalis.InstantMessaging.MessageWindow.prototype.getMessageTime = function () {
    var currentTime = new Date();
    var hour = currentTime.getHours();
    if (hour < 10) {
        hour = "0" + hour
    }
    var minute = currentTime.getMinutes();
    if (minute < 10) {
        minute = "0" + minute
    }
    var seconds = currentTime.getSeconds();
    if (seconds < 10) {
        seconds = "0" + seconds
    }
    return hour + ":" + minute + ":" + seconds;
};

Formalis.InstantMessaging.MessageWindow.prototype.checkIfOperatorWaitingDivIsLoaded = function () {
    if (this.Controls.OperatorWaitingHolder().length > 0) {
        return true;
    } else {
        return false;
    }

};

/**
* Deactivates message window controls (a new message can't be written, buttons won't work).
*
*/
Formalis.InstantMessaging.MessageWindow.prototype.deactiveControls = function () {
    this.bindDeactivateControls();
};
/**
* Deactivates message window controls (a new message can't be written, buttons won't work).
*
*/
Formalis.InstantMessaging.MessageWindow.prototype.clearMessagingWindow = function () {
    var mainHolder = this.Controls.MessagesHolder();
    mainHolder.html('');
};

Formalis.InstantMessaging.MessageWindow.prototype.refreshCustomFieldList = function () {

    var $ = Formalis.ClassBase.prototype.$;
    var customFieldDiv = this.Controls.UserCustomFieldList();

    postValues = {
        session: this._sessionID
    };

    customFieldDiv.load(this.Fields.SessionCustomFieldsUrl, postValues);
};

Formalis.InstantMessaging.MessageWindow.prototype.canDismissCustomFieldListChroneJob = function () {

    var $ = Formalis.ClassBase.prototype.$;

    var customFieldDiv = this.Controls.UserCustomFieldList();
    return $.trim(customFieldDiv.html()).length > 0;
};

Formalis.InstantMessaging.MessageWindow.prototype.markMessagesSent = function (acks) {

    if (acks == null || acks == undefined || acks.length == 0)
        return;

    var $ = Formalis.ClassBase.prototype.$;
    var obj = this;
    try {

        var processedMessageList = $.map( obj._messageRepository, function(val, index)
        {
            if(val.IsSent)
                return val;

            for (var i = 0; i < acks.length; i++) {

                var ack = acks[i];

                if(val.MessageSeqNo == ack.MessageSeqNo)
                {
                    val.IsSent = true;
                    break;
                }
            }

            return val;
        });

        obj._messageRepository = processedMessageList;
    } catch (err) {
        obj.HandleBusinessError(err);
    }
};


Formalis.InstantMessaging.MessageWindow.prototype.sendEmail = function () {
    try {
        this.sendEmailText();
    } catch (err) {
        this.HandleBusinessError(err);
    }
};

Formalis.InstantMessaging.MessageWindow.prototype.sendEmailError = function() {
    try {
        this.sendEmailErrorText();
    } catch(err) {
        this.HandleBusinessError(err);
    }
}