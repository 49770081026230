Formalis.InstantMessaging.MessageWindow.prototype.HTMLTemplates = {
    AppendNewMessage: '\
    {#foreach $T.Messages as message}\
    <div class="chat-frame-message-box {$T.message.getMessageUserTypeClass()}">\
        <div class="message">\
            <span class="tringle"></span>\
            <span class="text">{$T.message.MessageText}</span>\
            {#if !$T.message.getMessageUserTypeClass().Contains("system")}\
                {#if $T.message.MessageSeqNo != "" && $P.SelfMessage == 1 }\
                <span id="imgServerAck_{$T.message.MessageSeqNo}" class="{$P.ServerUnackImageSrc}"></span>\
                <span style="display:none;" id="imgRemoteCliAck_{$T.message.MessageSeqNo}" class="{$P.RemoteClientUnackImageSrc}"></span>\
                {#/if}\
                \
                <span class="time">[{$T.message.MessageTime}]</span>\
                <span class="name">{$T.message.Sender.Name} {$T.message.Sender.Surname} </span>\
            {#/if}\
        </div>\
    </div>\
    {#/for}',

    Invitations_OperatorWaiting:'                                                                                                       \
                <div class="chat-frame-message-box system">                                                                             \
                 <h4 class="first-text">{$P.OperatorWaitingText}</h4>                                                                   \
                </div>',
    OperatorInformation:'                                                                                                               \
                <div class="chat-frame-message-box system">                                                                             \
                 <h4 class="first-text {$P.SessionWithOperatorMessageClass}">{$P.Message}</h4>                                          \
                 </div>'
};