/**
 * @fileOverview Base Error Handler Class 
 * @name Formalis.ErrorHandler
 * @author Formalis\Mehmet Ali Köken
 * @version 1.0
 */

/**
 * @class Error handler static class holds functionality for handling exception
 */
Formalis.ErrorHandler = {};

Formalis.ErrorHandler.ErrorListToString= function(errorList){
    var retVal = "";
    retVal += Formalis.Utils.objectToString(errorList,1);
    //retVal += Formalis.Utils.arrayToString(errorList);
    //retVal += Formalis.Utils.simpleTypeToString(errorList);
    return retVal;
};

