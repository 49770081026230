/**
 * @fileOverview Custom Fields class definition
 * @author Kerem KAT
 * @version 0.1
 * @name Custom Fields
 * @requires Formalis.Base, Formalis.InstantMessaging
 */

/**
 * Custom Fields class manages related to Instant Messaging sessions and 
 * custom fields such as showing custom field entry forms for operators 
 * and customers. 
 * @class 
 */
Formalis.InstantMessaging.CustomFields = function() { };

Formalis.InstantMessaging.CustomFields.inheritsFrom(Formalis.ClassBase);