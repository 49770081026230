/**
 * Updates users online status
 * @param {integer} UserID User's ID
 * @returns {void} void
 */
Formalis.InstantMessaging.OnlineUsers.prototype.UpdateOnlineStatusDAO = function(userID){
    var obj = this;
    this.proxy.InvokeJSONP("onlineUsers","GET",{
        user:{
            UserID:userID
        }
    },new Array(Formalis.InstantMessaging.BusinessChannelID+""),function(data){
        if(data != null && data.Success===true){
            Formalis.InstantMessaging.HandleDAOResponse(data);
            obj.Handler_UpdateOnlineStatus(data);
        }else{
            obj.Handle_UpdateOnlineStatus_Error(data);
        }
    },function(err){
        obj.Handle_UpdateOnlineStatus_Error(err);
    },true,Formalis.InstantMessaging.SetAuthUrl);
};

/**
 * Gets online users for the current business channel
 * @param {integer} UserID User's ID
 * @returns {void} void
 */
Formalis.InstantMessaging.OnlineUsers.prototype.GetOnlineUsersDAO = function(userID){
    var obj = this;

    this.proxy.InvokeJSONP("onlineUsers/","GET",null,new Array(),function(data){
        if(data != null && data.Success===true){
            Formalis.InstantMessaging.HandleDAOResponse(data);
            obj.Handler_GetOnlineUsers(data);
        }else{
            obj.Handle_GetOnlineUsers_Error(data);
        }
    },function(err){
        obj.Handle_GetOnlineUsers_Error(err);
    },true,Formalis.InstantMessaging.SetAuthUrl);
};

/**
 * Gets online users for the current business channel
 * @param {integer} UserID User's ID
 * @returns {void} void
 */
Formalis.InstantMessaging.OnlineUsers.prototype.checkAuthorizationDAO = function(userID){
    var obj = this;

    this.proxy.InvokeJSONP("onlineUsers/","GET",null,new Array('authorization'),function(data){
        if(data != null && data.Success===true){
            Formalis.InstantMessaging.HandleDAOResponse(data);
            obj.Handler_CheckAuthorization(data);
        }else{
            obj.Handle_GetOnlineUsers_Error(data);
        }
    },function(err){
        obj.Handle_GetOnlineUsers_Error(err);
    },true,Formalis.InstantMessaging.SetAuthUrl);
};

