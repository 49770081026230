Formalis.PopupWindow = {
    windows: {},
    open: function (url, keyname, args, reopenIfDuplicate) {

        if (keyname == null) {
            keyname = '';
        }

        if (keyname === 'MessageWindow_undefined') {
            keyname = keyname.replace('undefined', Guid);
        }

        if (typeof (this.windows[keyname]) != "object"
            || this.windows[keyname] == null
            || this.windows[keyname] == undefined) {

            this.windows[keyname] = window.open(url, keyname, args);
        } else {
            if (!this.windows[keyname].closed) {

                if (reopenIfDuplicate === true) {
                    this.windows[keyname].location.href = url;
                }
            } else {
                this.windows[keyname] = window.open(url, keyname, args);
            }
        }

        if (this.windows[keyname] != null && this.windows[keyname] != undefined) {
            this.windows[keyname].focus();
        }

        return this.windows[keyname];
    },
    close: function (keyname) {
        if (this.windows[keyname] != undefined && this.windows[keyname] != null) {
            this.windows[keyname].close();
            window.Store.acceptedInvitationIds.pop(keyname);
            delete this.windows[keyname];
        }
    },
    returnValue: function (targetElementId, message, setFocus) {
        if (window.external != null
            && window.external != undefined
            && typeof (window.external.setReplyText) != 'undefined') {

            window.external.setReplyText(targetElementId, message, setFocus);
        }
        else {
            if (window.opener == null) {
                // can not close window if opener is not set. Just return.
                return;
            }

            var target = $(window.opener.document.getElementById(targetElementId));
            target.val(message);

            if (typeof (setFocus) != 'undefined') {
                if (setFocus) {
                    target.focus();
                    $(target.parent()).find(".chat-write-send a").css({ "opacity": "1", "pointer-events": "all", "cursor": "pointer" });
                }
            }
        }

        if (window.opener != null) {
            var button = $(window.opener.document.getElementById('btnMessageWindow_MessageSend'));
            button.removeAttr('disabled');
        }

        window.close();
    }
};

var Guid = function (separator) {
    /// <summary>
    /// Creates a unique id for identification purposes.
    /// </summary>
    /// <param name="separator" type="String" optional="true">
    /// The optional separator for grouping the generated segmants: default "-".
    /// </param>

    var delim = separator || "-";

    function S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }

    return (S4() + S4() + delim + S4() + delim + S4() + delim + S4() + delim + S4() + S4() + S4());
};
