/**
 * @fileOverview A collection of functions for records.
 * @name Javascript Base File - Namespace declaration
 * @author Formalis\Mehmet Ali Köken
 * @version 1.0
 */

/**
 * @namespace Formalis Namespace decleration
 */
var Formalis = {
    /**
     * @description  Loads JS File dynamically
     * @function
     * @param filename Javascript Source file path
     */
    LoadJSFile: function (filename) {
        var fileref = document.createElement('script')
        fileref.setAttribute("type", "text/javascript")
        fileref.setAttribute("src", filename)

        if (typeof fileref != "undefined")
            document.getElementsByTagName("head")[0].appendChild(fileref)
    },
    /**
     * @description  Loads CSS File dynamically
     * @function
     * @param filename CSS Source file path
     */
    LoadCSSFile: function (filename) {

        var fileref = document.createElement("link")
        fileref.setAttribute("rel", "stylesheet")
        fileref.setAttribute("type", "text/css")
        fileref.setAttribute("href", filename)
        if (typeof fileref != "undefined")
            document.getElementsByTagName("head")[0].appendChild(fileref)
    }
};

/**
 * @description <p>This function is a part of Function prototype and provides inheritance 
 * within the Javascript code. Two types of Inheritance is provided :
 * Normal Inheritance : Through prototype.
 * Virtual Inheritance : Through class instance
 * </p>
 * @function
 * @param parentClassOrObject Parent Class or Parent Class Instance
 */
Function.prototype.inheritsFrom = function (parentClassOrObject) {
    if (parentClassOrObject.constructor == Function) {
        //Normal Inheritance 
        this.prototype = new parentClassOrObject;
        this.prototype.constructor = this;
        this.prototype.parent = parentClassOrObject.prototype;
    }
    else {
        //Pure Virtual Inheritance 
        this.prototype = parentClassOrObject;
        this.prototype.constructor = this;
        this.prototype.parent = parentClassOrObject;
    }
    return this;
}