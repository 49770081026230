
/**
* Handler function for getNewInvitationsDAO method.
* This function checks if there are new invitations.
*  If there are new Invitations, produces new Invitations list table
*  else outputs error message indicating that there are no new invitations.
* @param {object} data WebService ajax call result
* @returns {void} void
*/
Formalis.InstantMessaging.Invitation.prototype.Handler_GetNewInvitations = function (data) {
    try {
        if (data == null || data.Error) {
            throw new Formalis.Exception(this.Resource.Lang.InvitationDataIsNull);
        }

        if (data.Validation != null
            && data.Validation != undefined
            && data.Validation.length > 0) {
            this.Validation_DisplayValidationMessages(data.Validation);
        }

        if (data.StopProcess) {
            this.stopAllProcesses();
            this.hideInvitationsPanel();
        }

        if (data.Invitations.length > 0) {
            var autoAssignFlag = true;
            data.Invitations.forEach(function (invitation) {
                autoAssignFlag = invitation.IsAutoAssign;
            });

            if (!autoAssignFlag) {
                this.GenerateNewInvitationsTable(data);
            } else {

                var skipThisRequest = false;
                var maxConcurrentOperatorSession;

                for (var key in Formalis.PopupWindow.windows) {
                    var item = Formalis.PopupWindow.windows[key];
                    if (!item.closed)
                        continue;

                    window.Store.acceptedInvitationIds.pop(key);
                    delete Formalis.PopupWindow.windows[key];
                }

                data.Invitations.forEach(function (invitation) {
                    maxConcurrentOperatorSession = invitation.MaxConcurrentOperatorSession;
                    if ((window.Contains(window.Store.acceptedInvitationIds, invitation.UniqueId)
                        || data.OnGoingSessionCount >= maxConcurrentOperatorSession)
                        && invitation.IsAutoAssign) {
                        skipThisRequest = true;
                        return;
                    }
                });

                if (data.OnGoingSessionCount >= maxConcurrentOperatorSession) {
                    this.GenerateNewInvitationsTable(data);
                }
                else {
                    this.bindStartSessionEvent(data.Invitations);
                }
            }
            this.bindStartSessionClickEvent();

        }
        else {
            this.ShowNoNewInvitationsWindow();
        }

        //        $.pnotify({
        //            pnotify_title: 'Invitations Recieved',
        //            pnotify_text: 'It\'s alright',
        //            pnotify_delay: 1000,
        //            pnotify_history: false
        //        });
        //this.bindDraggableEvent();
    } catch (e) {
        this.Handle_GetNewInvitations_Error(e);
    }
};
/**
* If Invitation Status is accepted, starts a new session.
* @param [object] data
*/
Formalis.InstantMessaging.Invitation.prototype.Handler_CheckInvitationStatus = function (data) {
    try {
        if (data == null || data.Error) {
            throw new Formalis.Exception(this.Resource.Lang.InvitationDataIsNull);
        }
        var obj = this;
        var messageWindow = null;
        if (data.IsDeleted) {
            obj.isInvitationPending = false;
            this.stopAllProcesses();
            messageWindow = new Formalis.InstantMessaging.MessageWindow();
            messageWindow.clearMessagingWindow();
            var messageTime = messageWindow.getMessageTime();
            var messageData = {
                Messages: new Array({
                    MessageType: { Value: 2 },
                    MessageTime: messageTime,
                    MessageText: obj.Resource.Lang.InvitationDeleted,
                    Sender: Formalis.InstantMessaging.CurrentUser,
                    MessageSeqNo: 'mseq' + obj.SeqNo
                })
            };
            messageWindow.appendNewMessages(messageData, Formalis.InstantMessaging.Enum.MessageSenderType.Participants);
            obj.SeqNo++;
            return;
        }

        if (data.IsAccepted) {
            this.unbindChroneJobEventHandlers_CheckInvitationStatus();
            obj.isInvitationPending = false;
            messageWindow = new Formalis.InstantMessaging.MessageWindow(data.SessionID);
            messageWindow._invitationTopic = this._invitationTopic;
            messageWindow.clearMessagingWindow();
            messageWindow.init();
        } else {
            messageWindow = new Formalis.InstantMessaging.MessageWindow(null);
            if (!messageWindow.checkIfOperatorWaitingDivIsLoaded()) {
                messageWindow.addOperatorWaitingText();
            }
        }
    } catch (e) {
        this.Handle_CheckInvitationStatus_Error();
    }
};

/**
* If Invitation Status is accepted, starts a new session.
* @param [object] data
*/
Formalis.InstantMessaging.Invitation.prototype.Handler_CheckAuthorization = function (data) {
    var obj = this;
    try {
        if (data == null || data.Error) {
            throw new Formalis.Exception(this.Resource.Lang.InvitationDataIsNull);
        }
        if (data.StopProcess) {
            this.stopAllProcesses();
            this.hideInvitationsPanel();
        } else {
            this._authorizedToGetInvitationList = true;
            this.startProcess();
        }
    } catch (e) {
        obj.Handle_CheckInvitationStatus_Error();
    }
};

/**
* If Invitation Status is accepted, starts a new session.
* Specific to
* @param [object] data
*/
Formalis.InstantMessaging.Invitation.prototype.Handler_CheckSendInvitationAuthorization = function (data) {

    try {
        if (data == null || data.Error) {
            throw new Formalis.Exception(this.Resource.Lang.InvitationDataIsNull);
        }
        if (data.StopProcess) {
            //this.stopAllProcesses();
        } else {
            var $ = Formalis.ClassBase.prototype.$;
            if (this.LiveSupportRequestButtonID != null
                && this.LiveSupportRequestButtonID != undefined
                && this.LiveSupportRequestButtonID != "") {
                var liveSupportRequestButton = $('#' + this.LiveSupportRequestButtonID);
                liveSupportRequestButton.show();
                this.bindLiveSupportButton(liveSupportRequestButton);
            }
        }
    } catch (e) {
        this.Handle_CheckSendInvitationAuthorization_Error();
    }
};
