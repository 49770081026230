/**
 * @fileOverview Holds Formalis Enum Definition
 * @name Formalis.Enum
 * @author Formalis\Mehmet Ali Köken
 * @version 1.0
 */

/**
 * Enum Object Initiator.
 */
Formalis.Enum = {};
/**
 * Cache Priority. First use in Formalis.Cache class.
 */
Formalis.Enum.CachePriority = {
    Low: 1,
    Normal: 2,
    High: 4
};
/**
 * Enum Language
 */
Formalis.Enum.Language = {
    Turkish: 1,
    English: 2
};

/**
 * Enum Exception Type
 */
Formalis.Enum.ExceptionType  = {
    Validation: 1,
    Functional: 2
};


/**
 * Enum CustomFieldUsage
 */
Formalis.Enum.CustomFieldUsage  = {
    Invalid:        0,
    PreSession:     1,
    PostSession:    2
};


/**
 * Enum CustomFieldFiller
 */
Formalis.Enum.CustomFieldFiller = {
    Invalid:    0,
    Operator:   1,
    Customer:   2
};